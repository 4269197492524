import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Auth } from 'aws-amplify'
// ANT DESIGN COMPONENT
import { Drawer, Avatar, Button } from 'antd'
// I18N TRANSLATION
import { useTranslation } from 'react-i18next'
// FONT AWESOME LIBRYARY AND ICONS
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faUser,
  faCircleCheck,
  faCircleExclamation,
  faBriefcase,
  faScrewdriverWrench,
  faEdit,
  faRightFromBracket,
  faSun,
  faMoon,
} from '@fortawesome/free-solid-svg-icons'
// REDUX
import { useSelector } from 'react-redux'
// COMPONENTS
import Appearance from './Appearance'
import Internationalization from './Internationalization'
// INTERFACE
import UserInformation from './interface/YourProfilePage'
// STYLES
import usaFlag from '../../assets/unitedStatesFlag.png'
import spainFlag from '../../assets/spainFlag.png'

library.add(
  faUser,
  faCircleCheck,
  faCircleExclamation,
  faBriefcase,
  faScrewdriverWrench,
  faEdit,
  faRightFromBracket,
  faSun,
  faMoon,
)

const YourProfileDrawer = () => {
  // ************************************************ */
  // LOCAL STORAGE AND VARIABLES ******************** */
  const [visible, setVisible] = useState(false)
  const [t] = useTranslation('global')
  const homeState = useSelector((state: any) => state.home)
  const [userProfileData, setUserProfileData] = useState<UserInformation>()
  const { language } = useSelector((state: any) => state.home)

  // ************************************************* */
  // FUNCTIONS ************************************** */
  const showDrawer = () => {
    setVisible(true)
  }

  const onClose = () => {
    setVisible(false)
  }

  const handleChangeHeaderTitle = (title: string) => {
    localStorage.setItem('headerTitle', title)
  }

  const signOut = async () => {
    await Auth.signOut()
    window.location.href = '/login'
  }

  // *********************************************** */
  // USE EFFECT ************************************ */
  useEffect(() => {
    // dispatch(getUserData())
  }, [])

  useEffect(() => {
    if (
      homeState &&
      homeState.userInformation &&
      homeState.userInformation.body
    ) {
      setUserProfileData(homeState.userInformation.body)
    }
  }, [homeState])

  // ************************************************* */
  // COMPONENT ************************************** */
  return (
    <>
      <div>
        <div
          className="generalStyles__flex"
          role="button"
          tabIndex={0}
          onClick={() => {
            showDrawer()
          }}
          onKeyDown={() => {
            showDrawer()
          }}
          style={{ marginRight: '25px' }}
        >
          <div style={{ marginLeft: '10px' }}>
            <Avatar
              className="button__button1"
              icon={<FontAwesomeIcon icon={faUser} />}
            />
          </div>
          <div>
            <img
              src={language === 'en' ? usaFlag : spainFlag}
              alt=""
              width="25"
              height="15"
              style={{ marginLeft: '10px' }}
            />
          </div>
        </div>
        <Drawer
          width="460"
          placement="right"
          onClose={onClose}
          closable={false}
          visible={visible}
        >
          {/** ************************************************* */}
          <div
            className="generalStyles__spaceBetween"
            style={{ marginBottom: '60px' }}
          >
            <div className="generalStyles__flex">
              <div>
                <Avatar
                  size={{ xs: 24, sm: 32, md: 40, lg: 64, xl: 80, xxl: 100 }}
                >
                  JT
                </Avatar>
              </div>
              <div style={{ marginLeft: '25px', marginTop: '20px' }}>
                <p className="drawer__userNameDrawer">Jose Alejandro Tamayo</p>
                <p style={{ marginTop: '-15px' }}>
                  atamayo@standard-elevator.com
                </p>
                <p style={{ marginTop: '-15px' }}>
                  <FontAwesomeIcon
                    icon={faCircleCheck}
                    className="generalStyles__success"
                    style={{ marginTop: '-25px' }}
                  />
                  <span style={{ marginLeft: '5px' }}> Administrator</span>
                </p>
              </div>
            </div>
          </div>
          {/** ************************************************* */}
          <Internationalization />
          {/** ************************************************* */}
          <Appearance />
          {userProfileData && (
            <div>
              {/** ************************************************* */}
              <div className="yourProfile__drawerContainerTitle yourProfile__editDrawerContainer">
                <div>
                  <p className="generalStyles__text">
                    <FontAwesomeIcon
                      icon={faUser}
                      style={{ marginRight: '10px' }}
                    />
                    {t('profile.userInformation.userInformation')}
                  </p>
                </div>
                <div>
                  <Link
                    onClick={() => {
                      // eslint-disable-next-line no-use-before-define
                      handleChangeHeaderTitle('yourProfile')
                      onClose()
                    }}
                    className="generalStyles__decorationNone"
                    to="/your-profile"
                  >
                    <FontAwesomeIcon
                      className="yourProfile__editIcon"
                      icon={faEdit}
                      style={{ marginRight: '10px' }}
                    />{' '}
                  </Link>
                </div>
              </div>
              <br />
              {/** */}
              <div className="generalStyles__flex">
                <div className="yourProfile__drawerContainerText1">
                  <p className="generalStyles__text">
                    {t('profile.userInformation.status')}
                  </p>
                </div>
                <div className="yourProfile__drawerContainerText2">
                  <p className="generalStyles__text">
                    {userProfileData.isActive && (
                      <p className="generalStyles__text">
                        <FontAwesomeIcon
                          className="yourProfile__activeItem"
                          icon={faCircleCheck}
                        />
                        Active
                      </p>
                    )}
                    {!userProfileData.isActive && (
                      <p className="generalStyles__text">
                        <FontAwesomeIcon
                          className="yourProfile__inactiveItem"
                          icon={faCircleExclamation}
                        />
                        Inactive
                      </p>
                    )}
                  </p>
                </div>
              </div>
              {/** */}
              <div className="generalStyles__flex">
                <div className="yourProfile__drawerContainerText1">
                  <p className="generalStyles__text">
                    {t('profile.userInformation.userId')}
                  </p>
                </div>
                <div className="yourProfile__drawerContainerText2">
                  <p className="generalStyles__text">
                    {userProfileData.id || 0}
                  </p>
                </div>
              </div>
              {/** */}
              <div className="generalStyles__flex">
                <div className="yourProfile__drawerContainerText1">
                  <p className="generalStyles__text">
                    {t('profile.userInformation.email')}
                  </p>
                </div>
                <div className="yourProfile__drawerContainerText2">
                  <p className="generalStyles__text">
                    {userProfileData.email || 0}
                  </p>
                </div>
              </div>
              {/** */}
              <div className="generalStyles__flex">
                <div className="yourProfile__drawerContainerText1">
                  <p className="generalStyles__text">
                    {' '}
                    {t('profile.userInformation.firstName')}
                  </p>
                </div>
                <div className="yourProfile__drawerContainerText2">
                  <p className="generalStyles__text">
                    {userProfileData.contactInfo.name || 0}
                  </p>
                </div>
              </div>
              {/** */}
              <div className="generalStyles__flex">
                <div className="yourProfile__drawerContainerText1">
                  <p className="generalStyles__text">
                    {t('profile.userInformation.middleName')}
                  </p>
                </div>
                <div className="yourProfile__drawerContainerText2">
                  <p className="generalStyles__text">
                    {userProfileData.contactInfo.middleName || 0}
                  </p>
                </div>
              </div>
              {/** */}
              <div className="generalStyles__flex">
                <div className="yourProfile__drawerContainerText1">
                  <p className="generalStyles__text">
                    {' '}
                    {t('profile.userInformation.lastName')}
                  </p>
                </div>
                <div className="yourProfile__drawerContainerText2">
                  <p className="generalStyles__text">
                    {userProfileData.contactInfo.lastName || 0}
                  </p>
                </div>
              </div>
              {/** */}
              <div className="generalStyles__flex">
                <div className="yourProfile__drawerContainerText1">
                  <p className="generalStyles__text">
                    {t('profile.userInformation.address')}
                  </p>
                </div>
                <div className="yourProfile__drawerContainerText2">
                  <p className="generalStyles__text">
                    {userProfileData.contactInfo.address || 0}
                  </p>
                </div>
              </div>
              {/** */}
              <div className="generalStyles__flex">
                <div className="yourProfile__drawerContainerText1">
                  <p className="generalStyles__text">
                    {t('profile.userInformation.phoneNumber')}
                  </p>
                </div>
                <div className="yourProfile__drawerContainerText2">
                  <p className="generalStyles__text">
                    {userProfileData.contactInfo.phoneNumber || 0}
                  </p>
                </div>
              </div>
              <br />
              <br />
              {/** ************************************************* */}
              <div className="yourProfile__drawerContainerTitle">
                <p className="generalStyles__text">
                  <FontAwesomeIcon
                    icon={faBriefcase}
                    style={{ marginRight: '10px' }}
                  />
                  {t('profile.organizationInformation.organizationInformation')}
                </p>
              </div>
              <br />
              {/** */}
              <div className="generalStyles__flex">
                <div className="yourProfile__drawerContainerText1">
                  <p className="generalStyles__text">
                    {t('profile.organizationInformation.address')}
                  </p>
                </div>
                <div className="yourProfile__drawerContainerText2">
                  <p className="generalStyles__text">
                    {userProfileData.organization.address || 0}
                  </p>
                </div>
              </div>
              {/** */}
              <div className="generalStyles__flex">
                <div className="yourProfile__drawerContainerText1">
                  <p className="generalStyles__text">
                    {t('profile.organizationInformation.city')}
                  </p>
                </div>
                <div className="yourProfile__drawerContainerText2">
                  <p className="generalStyles__text">
                    {userProfileData.organization.city || 0}
                  </p>
                </div>
              </div>
              {/** */}
              <div className="generalStyles__flex">
                <div className="yourProfile__drawerContainerText1">
                  <p className="generalStyles__text">
                    {t('profile.organizationInformation.country')}
                  </p>
                </div>
                <div className="yourProfile__drawerContainerText2">
                  <p className="generalStyles__text">
                    {userProfileData.organization.country || 0}
                  </p>
                </div>
              </div>
              {/** */}
              <div className="generalStyles__flex">
                <div className="yourProfile__drawerContainerText1">
                  <p className="generalStyles__text">
                    {t('profile.organizationInformation.description')}
                  </p>
                </div>
                <div className="yourProfile__drawerContainerText2">
                  <p className="generalStyles__text">
                    {userProfileData.organization.description || 0}
                  </p>
                </div>
              </div>
              {/** */}
              <div className="generalStyles__flex">
                <div className="yourProfile__drawerContainerText1">
                  <p className="generalStyles__text">
                    {t('profile.organizationInformation.name')}
                  </p>
                </div>
                <div className="yourProfile__drawerContainerText2">
                  <p className="generalStyles__text">
                    {userProfileData.organization.name || 0}
                  </p>
                </div>
              </div>
              {/** */}
              <div className="generalStyles__flex">
                <div className="yourProfile__drawerContainerText1">
                  <p className="generalStyles__text">
                    {t('profile.organizationInformation.parent')}
                  </p>
                </div>
                <div className="yourProfile__drawerContainerText2">
                  <p className="generalStyles__text">
                    {userProfileData.organization.parent || 0}
                  </p>
                </div>
              </div>
              {/** */}
              <div className="generalStyles__flex">
                <div className="yourProfile__drawerContainerText1">
                  <p className="generalStyles__text">
                    {t('profile.organizationInformation.phoneNumber')}
                  </p>
                </div>
                <div className="yourProfile__drawerContainerText2">
                  <p className="generalStyles__text">
                    {userProfileData.organization.phoneNumber || 0}
                  </p>
                </div>
              </div>
              {/** */}
              <div className="generalStyles__flex">
                <div className="yourProfile__drawerContainerText1">
                  <p className="generalStyles__text">
                    {t('profile.organizationInformation.state')}
                  </p>
                </div>
                <div className="yourProfile__drawerContainerText2">
                  <p className="generalStyles__text">
                    {userProfileData.organization.state || 0}
                  </p>
                </div>
              </div>
              {/** */}
              <div className="generalStyles__flex">
                <div className="yourProfile__drawerContainerText1">
                  <p className="generalStyles__text">
                    {t('profile.organizationInformation.type')}
                  </p>
                </div>
                <div className="yourProfile__drawerContainerText2">
                  <p className="generalStyles__text">
                    {userProfileData.organization.type || 0}
                  </p>
                </div>
              </div>
              <br />
              <br />
              {/** ************************************************* */}
              <div className="yourProfile__drawerContainerTitle">
                <p className="generalStyles__text">
                  <FontAwesomeIcon
                    icon={faScrewdriverWrench}
                    style={{ marginRight: '10px' }}
                  />
                  {t('profile.roleInformation.roleInformation')}
                </p>
              </div>
              <br />
              {/** */}
              <div className="generalStyles__flex">
                <div className="yourProfile__drawerContainerText1">
                  <p className="generalStyles__text">
                    {t('profile.roleInformation.name')}
                  </p>
                </div>
                <div className="yourProfile__drawerContainerText2">
                  <p className="generalStyles__text">
                    {userProfileData.role.name || 0}
                  </p>
                </div>
              </div>
              {/** */}
              <div className="generalStyles__flex">
                <div className="yourProfile__drawerContainerText1">
                  <p className="generalStyles__text">
                    {t('profile.roleInformation.type')}
                  </p>
                </div>
                <div className="yourProfile__drawerContainerText2">
                  <p className="generalStyles__text">
                    {userProfileData.role.type || 0}
                  </p>
                </div>
              </div>
              <br />
              <br />
            </div>
          )}
          {/** ************************************************* */}
          <div className="drawer__signOutButtonContainer">
            <div>
              <Button
                className="drawer__logoutButton"
                type="primary"
                shape="round"
                icon={
                  <FontAwesomeIcon
                    icon={faRightFromBracket}
                    style={{ marginRight: '10px' }}
                  />
                }
                onClick={signOut}
              >
                {t('header.signOut')}
              </Button>
            </div>
          </div>
        </Drawer>
      </div>
    </>
  )
}

export default YourProfileDrawer
