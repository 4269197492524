import React, { useEffect, useState } from 'react'
// ANT DESIGN COMPONENTS
import { Modal, Button, Input, Spin } from 'antd'
// I18N TRANSLATION
import { useTranslation } from 'react-i18next'
// FONT AWESOME LIBRYARY AND ICONS
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faTrash,
  faTriangleExclamation,
} from '@fortawesome/free-solid-svg-icons'
// REDUX
import { useDispatch, useSelector } from 'react-redux'
import { useDeleteRoleMutation } from '../../redux/roles/roleAPI'
// AUTHORIZATION
import GETJwtToken from '../../redux/authentication'
import {
  updateOpenModalDeleteRole,
  updateRoleAlert,
} from '../../redux/roles/roleSlice'

library.add(faTrash, faTriangleExclamation)

function RolesDeleteRoleModal() {
  // ************************************************ */
  // LOCAL STORAGE AND VARIABLES ******************** */
  const dispatch = useDispatch()
  const { role, openModalDeleteRole } = useSelector((state: any) => state.role)
  const [disableButtonConfirmDelete, setDisableButtonConfirmDelete] = useState(
    true,
  )
  const [confirmRoleName, setConfirmRoleName] = useState('')
  const [
    triggerDeleteRole,
    { isLoading, isSuccess, isError, reset: resetDeleteRole },
  ] = useDeleteRoleMutation()
  const [t] = useTranslation('global')

  // ************************************************ */
  // FUNCTIONS ************************************** */
  const handleOk = () => {
    dispatch(updateOpenModalDeleteRole(false))
  }

  const handleCancel = () => {
    dispatch(updateOpenModalDeleteRole(false))
  }

  const handleCloseModal = () => {
    dispatch(updateOpenModalDeleteRole(false))
  }

  const handleDeleteItem = async () => {
    const token = await GETJwtToken()
    triggerDeleteRole({ id: role.id, token })
  }

  const handleSubmitDeleteItem = async (event: any) => {
    if (event.key === 'Enter') {
      const token = await GETJwtToken()
      triggerDeleteRole({ id: role.id, token })
    }
  }

  // ************************************************* */
  // USE EFFECT ************************************** */
  useEffect(() => {
    if (openModalDeleteRole) {
      setDisableButtonConfirmDelete(true)
      setConfirmRoleName('')
      resetDeleteRole()
    }
  }, [openModalDeleteRole])

  useEffect(() => {
    setDisableButtonConfirmDelete(true)
    if (
      confirmRoleName &&
      role &&
      role.name &&
      confirmRoleName.toLowerCase() === role.name.toLowerCase()
    ) {
      setDisableButtonConfirmDelete(false)
    } else {
      setDisableButtonConfirmDelete(true)
    }
  }, [confirmRoleName])

  useEffect(() => {
    if (isSuccess) {
      setTimeout(() => {
        dispatch(
          updateRoleAlert({
            title: 'Success',
            description: 'Role deleted successfully',
            status: 'success',
          }),
        )
      }, 150)
      dispatch(updateOpenModalDeleteRole(false))
    }
    if (isError) {
      setTimeout(() => {
        dispatch(
          updateRoleAlert({
            title: 'Error',
            description:
              'Something went wrong! Please reload the page and try again.',
            status: 'error',
          }),
        )
      }, 150)
      dispatch(updateOpenModalDeleteRole(false))
    }
  }, [isSuccess, isError])

  // ************************************************ */
  // COMPONENT ************************************** */
  return (
    <Modal
      onOk={handleOk}
      closable={false}
      onCancel={handleCancel}
      visible={openModalDeleteRole}
      footer={null}
    >
      <Spin spinning={isLoading}>
        <div>
          <div className="text-center">
            <FontAwesomeIcon
              icon={faTriangleExclamation}
              className="generalStyles__warning generalStyles__deleteItemIcon"
            />
            <p className="mt-3 generalStyles__text">
              {t('organizationManagement.deleteDescription.text1')}
              <b className="generalStyles__warning">
                {t('organizationManagement.deleteDescription.text2')}{' '}
              </b>
              {t('organizationManagement.deleteDescription.text7')}{' '}
              <b className="generalStyles__warning">
                {role && role.name ? role.name : ''}{' '}
              </b>
              {t('organizationManagement.deleteDescription.text8')}
            </p>
            <Input
              placeholder={t('organizationManagement.roleName')}
              onChange={(e) => {
                setConfirmRoleName(e.target.value)
              }}
              value={confirmRoleName}
              onKeyDown={handleSubmitDeleteItem}
              status="warning"
            />
          </div>
          <div className="generalStyles__flexEnd mt-4">
            <div>
              <Button
                htmlType="submit"
                className={
                  disableButtonConfirmDelete
                    ? 'buttonStyle__inactive__1'
                    : 'buttonStyle__2'
                }
                style={{ marginRight: '10px' }}
                onClick={handleDeleteItem}
                disabled={disableButtonConfirmDelete}
              >
                {t('organizationManagement.delete')}
              </Button>
              <Button onClick={handleCloseModal} type="default">
                {t('organizationManagement.close')}
              </Button>
            </div>
          </div>
        </div>
      </Spin>
    </Modal>
  )
}

export default RolesDeleteRoleModal
