import React from 'react'
// COMPONENTS
import RolesTable from './RolesTable'
import RolesHeader from './RolesHeader'
import RolesDrawer from './RolesDrawer'
import RolesCreateRoleForm from './RolesCreateRoleForm'
import RolesDeleteRoleModal from './RolesDeleteRoleModal'

// eslint-disable-next-line arrow-body-style
const RolesPage = () => {
  // ************************************************ */
  // LOCAL STORAGE AND VARIABLES ******************** */

  // ************************************************* */
  // USE EFFECT ************************************** */

  // ************************************************ */
  // COMPONENT ************************************** */
  return (
    <div>
      <div className="generalStyles__pageBox">
        <RolesHeader />
        <RolesTable />
        <RolesDrawer />
        <RolesCreateRoleForm />
        <RolesDeleteRoleModal />
      </div>
    </div>
  )
}

export default RolesPage
