import React, { useEffect, useState } from 'react'
// ANT DESIGN COMPONENTS
import {
  Drawer,
  Button,
  Form,
  Input,
  Select,
  InputNumber,
  Spin,
  Divider,
} from 'antd'
// FONT AWESOME LIBRYARY AND ICONS
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBuildingCircleExclamation } from '@fortawesome/free-solid-svg-icons'
// I18N TRANSLATION
import { useTranslation } from 'react-i18next'
// REDUX
import { useDispatch, useSelector } from 'react-redux'
import { usePutOrganizationMutation } from '../../redux/organizations/organizationAPI'
import {
  updateOpenModalEditOrganization,
  updateOrganizationAlert,
} from '../../redux/organizations/organizationSlice'
// AUTHORIZATION
import GETJwtToken from '../../redux/authentication'
import {
  useGetCountriesMutation,
  useGetStatesMutation,
  useGetCitiesMutation,
} from '../../redux/countriesStatesCitiesAPI'

const { Option } = Select
library.add(faBuildingCircleExclamation)

function OrganizationsEditOrganizationForm() {
  // ************************************************ */
  // LOCAL STORAGE AND VARIABLES ******************** */
  const dispatch = useDispatch()
  const {
    organization,
    organizationList,
    openModalEditOrganization,
  } = useSelector((state: any) => state.organization)
  const [t] = useTranslation('global')
  //
  const [form] = Form.useForm()
  const [country, setCountry] = useState('')
  const [listCountries, setListCountries] = useState<any[]>([])
  const [listStates, setListStates] = useState<any[]>([])
  const [listCities, setListCities] = useState<any[]>([])

  //
  const [getCountries, { data: countries }] = useGetCountriesMutation()
  const [
    getStates,
    { data: states, isLoading: isLoadingStates },
  ] = useGetStatesMutation()
  const [
    getCities,
    { data: cities, isLoading: isLoadingCities },
  ] = useGetCitiesMutation()
  //
  const [
    putOrganization,
    {
      isSuccess: isSuccessPutOrganization,
      isError: isErrorPutOrganization,
      isLoading: isLoadingPutOrganization,
      reset: resetPutOrganization,
    },
  ] = usePutOrganizationMutation()

  // ************************************************ */
  // FUNCTIONS ************************************** */
  const onReset = () => {
    form.resetFields()
  }

  const onResetStateCity = () => {
    form.setFieldsValue({
      state: '',
      city: '',
    })
  }

  const handleCloseModal = () => {
    dispatch(updateOpenModalEditOrganization(false))
  }

  const handleChangeCountry = (value: string) => {
    onResetStateCity()
    setCountry(value)
    getStates(value)
  }

  const handleChangeState = (value: string) => {
    getCities({ country, state: value })
  }

  const onFinish = async (values: any) => {
    const token = await GETJwtToken()
    const BODY = {
      id: organization.id,
      body: {
        Name: values.name,
        Address: values.address,
        phone: values.phone.toString(),
        phone_code: values.dialCode.toString(),
        Type: values.type,
        Country: values.country,
        parent_id: organization.parent_id,
        State: values.state,
        City: values.city,
      },
      token,
    }
    putOrganization(BODY)
  }

  const onClose = () => {
    onReset()
    dispatch(updateOpenModalEditOrganization(false))
  }

  const onFill = () => {
    form.setFieldsValue({
      name: organization.name || '',
      address: organization.address,
      phone: parseInt(organization.phone, 10),
      dialCode: parseInt(organization.phone_code, 10),
      type: organization.type || '',
      country: organization.country || '',
      state: organization.state || '',
      city: organization.city || '',
      parentOrganization: organization.parent_id,
    })
  }

  const fillOrganizationsObject = () => {
    //
    const cleanList: any = []
    if (organizationList && organizationList.length > 0) {
      // eslint-disable-next-line array-callback-return
      organizationList.map((item: any) => {
        if (item.type === 'MASTER' || item.type === 'ORG') {
          cleanList.push(item)
        }
      })
    }
  }

  // ************************************************* */
  // USE EFFECT ************************************** */

  useEffect(() => {
    onFill()
    resetPutOrganization()
    if (openModalEditOrganization) {
      getCountries({})
    }
  }, [openModalEditOrganization])

  useEffect(() => {
    if (countries && countries.data && countries.data.length > 0) {
      setListCountries(countries.data)
    }
  }, [countries])

  useEffect(() => {
    if (
      states &&
      states.data &&
      states.data.states &&
      states.data.states.length > 0
    ) {
      setListStates(states.data.states)
    }
  }, [states])

  useEffect(() => {
    if (cities && cities.data && cities.data.length > 0) {
      setListCities(cities.data)
    }
  }, [cities])

  useEffect(() => {
    if (isSuccessPutOrganization) {
      setTimeout(() => {
        dispatch(
          updateOrganizationAlert({
            title: 'Success',
            description: 'Organization updated successfully',
            status: 'success',
          }),
        )
      }, 150)
      dispatch(updateOpenModalEditOrganization(false))
    }
    if (isErrorPutOrganization) {
      setTimeout(() => {
        dispatch(
          updateOrganizationAlert({
            title: 'Error',
            description:
              'Something went wrong! Please reload the page and try again.',
            status: 'error',
          }),
        )
      }, 150)
      dispatch(updateOpenModalEditOrganization(false))
    }
  }, [isSuccessPutOrganization, isErrorPutOrganization])

  useEffect(() => {
    fillOrganizationsObject()
  }, [organizationList])
  // ************************************************ */
  // COMPONENT ************************************** */
  return (
    <Drawer
      width="35%"
      placement="right"
      onClose={onClose}
      closable={false}
      visible={openModalEditOrganization}
    >
      <Spin
        spinning={
          isLoadingPutOrganization || isLoadingCities || isLoadingStates
        }
        tip={t('general.loading')}
      >
        <div>
          <h5>
            <FontAwesomeIcon
              style={{ marginRight: '10px' }}
              icon={faBuildingCircleExclamation}
              className="generalStyles__info"
            />
            {t('organizationManagement.editOrganization')}
          </h5>
          <Divider />
        </div>
        <Form form={form} layout="vertical" onFinish={onFinish}>
          <div className="container">
            <div className="row">
              <div className="col-12">
                {/** ---------------------------------------------------- */}
                {/** NAME */}
                <Form.Item
                  name="name"
                  label={
                    <>
                      <span className="generalStyles__text">
                        {t('organizationManagement.organizationName')}
                      </span>
                    </>
                  }
                  rules={[
                    {
                      required: true,
                      message: t(
                        'organizationManagement.formRequiredMessage.name',
                      ),
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </div>
              <div className="col-12">
                {/** ---------------------------------------------------- */}
                {/** TYPE */}
                <Form.Item
                  name="type"
                  label={
                    <>
                      <span className="generalStyles__text">
                        {t('organizationManagement.type')}
                      </span>
                    </>
                  }
                  rules={[
                    {
                      required: true,
                      message: t(
                        'organizationManagement.formRequiredMessage.type',
                      ),
                    },
                  ]}
                >
                  <Input disabled />
                </Form.Item>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                {/** ---------------------------------------------------- */}
                {/** ADDRESS */}
                <Form.Item
                  name="address"
                  label={
                    <>
                      <span className="generalStyles__text">
                        {t('organizationManagement.address')}
                      </span>
                    </>
                  }
                  rules={[
                    {
                      required: true,
                      message: t(
                        'organizationManagement.formRequiredMessage.address',
                      ),
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                {/** ---------------------------------------------------- */}
                {/** PHONE */}
                <Form.Item
                  name="phone"
                  label={
                    <>
                      <span className="generalStyles__text">
                        {t('organizationManagement.phoneNumber')}
                      </span>
                    </>
                  }
                  rules={[
                    {
                      required: true,
                      message: t(
                        'organizationManagement.formRequiredMessage.phone',
                      ),
                      type: 'number',
                    },
                  ]}
                >
                  <InputNumber
                    addonBefore={
                      <Form.Item
                        name="dialCode"
                        rules={[
                          {
                            required: true,
                            message: t(
                              'userManagement.formRequiredMessage.dialCode',
                            ),
                          },
                        ]}
                        noStyle
                      >
                        <InputNumber
                          prefix="+"
                          style={{
                            width: '100px',
                            marginLeft: '-12px',
                            marginRight: '-12px',
                            borderTop: 'none',
                            borderBottom: 'none',
                          }}
                        />
                      </Form.Item>
                    }
                    style={{ width: '100%' }}
                  />
                </Form.Item>
              </div>
              <div className="col-12">
                {/** ---------------------------------------------------- */}
                {/** COUNTRY */}
                <Form.Item
                  name="country"
                  label={
                    <>
                      <span className="generalStyles__text">
                        {t('organizationManagement.country')}
                      </span>
                    </>
                  }
                  rules={[
                    {
                      required: true,
                      message: t(
                        'organizationManagement.formRequiredMessage.country',
                      ),
                    },
                  ]}
                >
                  <Select
                    showSearch
                    optionFilterProp="children"
                    onChange={handleChangeCountry}
                    filterOption={(input: any, option: any) =>
                      option.children
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                  >
                    {listCountries.map((c) => (
                      <Option key={c.name} value={c.name}>
                        {c.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                {/** ---------------------------------------------------- */}
                {/** STATE */}
                <Form.Item
                  name="state"
                  label={
                    <>
                      <span className="generalStyles__text">
                        {t('organizationManagement.state')}
                      </span>
                    </>
                  }
                  rules={[
                    {
                      required: true,
                      message: t(
                        'organizationManagement.formRequiredMessage.state',
                      ),
                    },
                  ]}
                >
                  <Select
                    disabled={listStates.length === 0}
                    showSearch
                    optionFilterProp="children"
                    onChange={handleChangeState}
                    filterOption={(input: any, option: any) =>
                      option.children
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                  >
                    {listStates.map((st) => (
                      <Option key={st.name} value={st.name}>
                        {st.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </div>
              <div className="col-12">
                {/** ---------------------------------------------------- */}
                {/** CITY */}
                <Form.Item
                  name="city"
                  label={
                    <>
                      <span className="generalStyles__text">
                        {t('organizationManagement.city')}
                      </span>
                    </>
                  }
                >
                  <Select
                    disabled={listCities.length === 0}
                    showSearch
                    optionFilterProp="children"
                    filterOption={(input: any, option: any) =>
                      option.children
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                  >
                    {listCities.map((c) => (
                      <Option key={c} value={c}>
                        {c}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </div>
            </div>
          </div>
          {/** ---------------------------------------------------- */}
          <div className="mt-4">
            <div>
              <Button
                htmlType="submit"
                disabled={isLoadingPutOrganization}
                style={{ marginRight: '10px' }}
                type="primary"
              >
                {t('organizationManagement.submit')}
              </Button>
              <Button onClick={handleCloseModal}>
                {t('organizationManagement.close')}
              </Button>
            </div>
          </div>
        </Form>
      </Spin>
    </Drawer>
  )
}

export default OrganizationsEditOrganizationForm
