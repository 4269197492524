import React, { useEffect, useState } from 'react'
// ANT DESIGN COMPONENTS
import { Divider, Drawer } from 'antd'
// I18N TRANSLATION
import { useTranslation } from 'react-i18next'
// FONT AWESOME LIBRYARY AND ICONS
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faUser,
  faBuilding,
  faBuildingLock,
} from '@fortawesome/free-solid-svg-icons'
// REDUX
import { useDispatch, useSelector } from 'react-redux'
import { updateOpenDrawerOrganization } from '../../redux/organizations/organizationSlice'

library.add(faUser, faBuilding, faBuildingLock)

function OrganizationDrawer() {
  // ************************************************ */
  // LOCAL STORAGE AND VARIABLES ******************** */
  const dispatch = useDispatch()
  const { organization, openDrawerOrganization } = useSelector(
    (state: any) => state.organization,
  )
  const [data, setData] = useState<any>({})
  const [t] = useTranslation('global')
  // ************************************************ */
  // FUNCTIONS ************************************** */
  const onClose = () => {
    dispatch(updateOpenDrawerOrganization(false))
  }

  // ************************************************* */
  // USE EFFECT ************************************** */
  useEffect(() => {
    if (organization) {
      setData(organization)
    }
  }, [organization])

  // ************************************************ */
  // COMPONENT ************************************** */
  return (
    <Drawer
      width="35%"
      placement="right"
      onClose={onClose}
      closable={false}
      visible={openDrawerOrganization}
    >
      {data && (
        <div>
          {/** ************************************************* */}
          <div>
            <h5>
              <p className="generalStyles__text">
                <FontAwesomeIcon
                  style={{ marginRight: '10px' }}
                  icon={faBuilding}
                  className="generalStyles__info"
                />
                {t('organizationManagement.organizationInformation')}
              </p>
            </h5>
            <Divider />
          </div>
          {/* -*-*-*-*-*-*-*-*-*-*-*-*-*-*- */}
          <div className="generalStyles__flex">
            <div className="yourProfile__drawerContainerText1">
              <p className="drawer__title">ID:</p>
            </div>
            <div className="yourProfile__drawerContainerText2">
              <p className="generalStyles__text">{data.id ? data.id : '--'}</p>
            </div>
          </div>
          {/* -*-*-*-*-*-*-*-*-*-*-*-*-*-*- */}
          <div className="generalStyles__flex">
            <div className="yourProfile__drawerContainerText1">
              <p className="drawer__title">
                {t('organizationManagement.name')}
              </p>
            </div>
            <div className="yourProfile__drawerContainerText2">
              <p className="generalStyles__text">
                {data.name ? data.name : '--'}
              </p>
            </div>
          </div>
          {/* -*-*-*-*-*-*-*-*-*-*-*-*-*-*- */}
          <div className="generalStyles__flex">
            <div className="yourProfile__drawerContainerText1">
              <p className="drawer__title">
                {t('organizationManagement.type')}
              </p>
            </div>
            <div className="yourProfile__drawerContainerText2">
              <p className="generalStyles__text">
                {data.type ? data.type : '--'}
              </p>
            </div>
          </div>
          {/* -*-*-*-*-*-*-*-*-*-*-*-*-*-*- */}
          <div className="generalStyles__flex">
            <div className="yourProfile__drawerContainerText1">
              <p className="drawer__title">
                {t('organizationManagement.address')}
              </p>
            </div>
            <div className="yourProfile__drawerContainerText2">
              <p className="generalStyles__text">
                {data.address ? data.address : '--'}
              </p>
            </div>
          </div>
          {/* -*-*-*-*-*-*-*-*-*-*-*-*-*-*- */}
          <div className="generalStyles__flex">
            <div className="yourProfile__drawerContainerText1">
              <p className="drawer__title">
                {t('organizationManagement.country')}
              </p>
            </div>
            <div className="yourProfile__drawerContainerText2">
              <p className="generalStyles__text">
                {data.country ? data.country : '--'}
              </p>
            </div>
          </div>
          {/* -*-*-*-*-*-*-*-*-*-*-*-*-*-*- */}
          <div className="generalStyles__flex">
            <div className="yourProfile__drawerContainerText1">
              <p className="drawer__title">
                {t('organizationManagement.state')}
              </p>
            </div>
            <div className="yourProfile__drawerContainerText2">
              <p className="generalStyles__text">
                {data.state ? data.state : '--'}
              </p>
            </div>
          </div>
          {/* -*-*-*-*-*-*-*-*-*-*-*-*-*-*- */}
          <div className="generalStyles__flex">
            <div className="yourProfile__drawerContainerText1">
              <p className="drawer__title">
                {t('organizationManagement.city')}
              </p>
            </div>
            <div className="yourProfile__drawerContainerText2">
              <p className="generalStyles__text">
                {data.city ? data.city : '--'}
              </p>
            </div>
          </div>
          {/* -*-*-*-*-*-*-*-*-*-*-*-*-*-*- */}
          <div className="generalStyles__flex">
            <div className="yourProfile__drawerContainerText1">
              <p className="drawer__title">
                {t('organizationManagement.phoneNumber')}
              </p>
            </div>
            <div className="yourProfile__drawerContainerText2">
              <p className="generalStyles__text">
                {data.phone ? data.phone : '--'}
              </p>
            </div>
          </div>
          {/* -*-*-*-*-*-*-*-*-*-*-*-*-*-*- */}
          <div className="generalStyles__flex">
            <div className="yourProfile__drawerContainerText1">
              <p className="drawer__title">
                {t('organizationManagement.dialCode')}
              </p>
            </div>
            <div className="yourProfile__drawerContainerText2">
              <p className="generalStyles__text">
                {data.phone_code ? data.phone_code : '--'}
              </p>
            </div>
          </div>
          {/** ************************************************* */}
          <br />
          <br />
          <div>
            <h5>
              <p className="generalStyles__text">
                <FontAwesomeIcon
                  style={{ marginRight: '10px' }}
                  icon={faBuildingLock}
                  className="generalStyles__info"
                />
                {t('organizationManagement.organizationParent')}
              </p>
            </h5>
            <Divider />
          </div>

          {/* -*-*-*-*-*-*-*-*-*-*-*-*-*-*- */}
          <div className="generalStyles__flex">
            <div className="yourProfile__drawerContainerText1">
              <p className="drawer__title">
                {t('organizationManagement.parentId')}
              </p>
            </div>
            <div className="yourProfile__drawerContainerText2">
              <p className="generalStyles__text">
                {data.parent_id ? data.parent_id : '--'}
              </p>
            </div>
          </div>
        </div>
      )}
    </Drawer>
  )
}

export default OrganizationDrawer
