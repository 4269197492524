import React, { useEffect, useState } from 'react'
// ANT DESIGN COMPONENTS
import { Input, Divider } from 'antd'
// I18N TRANSLATION
import { useTranslation } from 'react-i18next'
// FONT AWESOME LIBRYARY AND ICONS
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faUser,
  faBriefcase,
  faScrewdriverWrench,
  faCircleCheck,
  faCircleExclamation,
} from '@fortawesome/free-solid-svg-icons'
// REDUX
import { useSelector } from 'react-redux'
// INTERFACE
import UserInformation from './interface/YourProfilePage'

library.add(
  faUser,
  faBriefcase,
  faScrewdriverWrench,
  faCircleCheck,
  faCircleExclamation,
)

const YourProfile = () => {
  // ************************************************ */
  // LOCAL STORAGE AND VARIABLES ******************** */
  const [userProfileData, setUserProfileData] = useState<UserInformation>()
  const [t] = useTranslation('global')
  const homeState = useSelector((state: any) => state.home)

  // ************************************************* */
  // USE EFFECT ************************************** */
  useEffect(() => {}, [])

  useEffect(() => {
    if (
      homeState &&
      homeState.userInformation &&
      homeState.userInformation.body
    ) {
      setUserProfileData(homeState.userInformation.body)
    }
  }, [homeState])

  // ************************************************ */
  // COMPONENT ************************************** */
  return (
    <div>
      {/* ********************************************************** */}
      {/* USER PROFILE DATA **************************************** */}
      {userProfileData && userProfileData.contactInfo && (
        <>
          <Divider orientation="left" plain>
            <FontAwesomeIcon
              className="generalStyles__infoIcon "
              icon={faUser}
            />
            <span className="yourProfile__dividerTitle yourProfile__title">
              {t('profile.userInformation.userInformation')}
            </span>
          </Divider>

          <div className="container yourProfile__inputContainer generalStyles__boxShadow">
            {/** ********************************************** */}
            {/**  USER ID / STATUS */}
            <div className="row mt-2">
              <div className="col-12 col-md-6">
                <p className="yourProfile__title">
                  {t('profile.userInformation.status')}
                </p>
                <Input
                  value={userProfileData.id || 0}
                  className="yourProfile__inputBackground"
                />
              </div>
              <div className="col-12 col-md-6">
                <p className="yourProfile__title">
                  {t('profile.userInformation.userId')}
                </p>
                <Input
                  value={userProfileData.id || 0}
                  className="yourProfile__inputBackground"
                />
              </div>
            </div>
            {/** ********************************************** */}
            {/**  EMAIL / FIRST NAME */}
            <div className="row mt-2">
              <div className="col-12 col-md-6">
                <p className="yourProfile__title">
                  {t('profile.userInformation.email')}
                </p>
                <Input
                  value={userProfileData.email || ''}
                  className="yourProfile__inputBackground"
                />
              </div>
              <div className="col-12 col-md-6">
                <p className="yourProfile__title">
                  {t('profile.userInformation.firstName')}
                </p>
                <Input
                  value={userProfileData.contactInfo.name || ''}
                  className="yourProfile__inputBackground"
                />
              </div>
            </div>
            {/** ********************************************** */}
            {/**  MIDDLE NAME / LAST NAME */}
            <div className="row mt-2">
              <div className="col-12 col-md-6">
                <p className="yourProfile__title">
                  {t('profile.userInformation.middleName')}
                </p>
                <Input
                  value={userProfileData.contactInfo.middleName || ''}
                  className="yourProfile__inputBackground"
                />
              </div>
              <div className="col-12 col-md-6">
                <p className="yourProfile__title">
                  {t('profile.userInformation.lastName')}
                </p>
                <Input
                  value={userProfileData.contactInfo.lastName || ''}
                  className="yourProfile__inputBackground"
                />
              </div>
            </div>
            {/** ********************************************** */}
            {/**  ADDRESS / PHONE NUMBER */}
            <div className="row mt-2">
              <div className="col-12 col-md-6">
                <p className="yourProfile__title">
                  {t('profile.userInformation.address')}
                </p>
                <Input
                  value={userProfileData.contactInfo.address || ''}
                  className="yourProfile__inputBackground"
                />
              </div>
              <div className="col-12 col-md-6">
                <p className="yourProfile__title">
                  {t('profile.userInformation.phoneNumber')}
                </p>
                <Input
                  value={userProfileData.contactInfo.phoneNumber || ''}
                  className="yourProfile__inputBackground"
                />
              </div>
            </div>
          </div>
        </>
      )}
      <br />
      <br />
      {/* ********************************************************** */}
      {/* ORGANIZATION DATA **************************************** */}
      {userProfileData && userProfileData.organization && (
        <>
          <Divider orientation="left" plain>
            <FontAwesomeIcon
              className="generalStyles__infoIcon "
              icon={faBriefcase}
            />
            <span className="yourProfile__dividerTitle yourProfile__title">
              {t('profile.organizationInformation.organizationInformation')}
            </span>
          </Divider>
          <div className="container yourProfile__inputContainer generalStyles__boxShadow">
            {/** ********************************************** */}
            {/**  ADDRESS / CITY */}
            <div className="row mt-2">
              <div className="col-12 col-md-6">
                <p className="yourProfile__title">
                  {t('profile.organizationInformation.address')}
                </p>
                <Input
                  value={userProfileData.organization.address || ''}
                  className="yourProfile__inputBackground"
                />
              </div>
              <div className="col-12 col-md-6">
                <p className="yourProfile__title">
                  {t('profile.organizationInformation.city')}
                </p>
                <Input
                  value={userProfileData.organization.city || ''}
                  className="yourProfile__inputBackground"
                />
              </div>
            </div>
            {/** ********************************************** */}
            {/**  COUNTRY / DESCRIPTION */}
            <div className="row mt-2">
              <div className="col-12 col-md-6">
                <p className="yourProfile__title">
                  {t('profile.organizationInformation.country')}
                </p>
                <Input
                  value={userProfileData.organization.country || ''}
                  className="yourProfile__inputBackground"
                />
              </div>
              <div className="col-12 col-md-6">
                <p className="yourProfile__title">
                  {t('profile.organizationInformation.description')}
                </p>
                <Input
                  value={userProfileData.organization.description || ''}
                  className="yourProfile__inputBackground"
                />
              </div>
            </div>
            {/** ********************************************** */}
            {/**  ORGANIZATION NAME / PARENT */}
            <div className="row mt-2">
              <div className="col-12 col-md-6">
                <p className="yourProfile__title">
                  {t('profile.organizationInformation.name')}
                </p>
                <Input
                  value={userProfileData.organization.name || ''}
                  className="yourProfile__inputBackground"
                />
              </div>
              <div className="col-12 col-md-6">
                <p className="yourProfile__title">
                  {t('profile.organizationInformation.parent')}
                </p>
                <Input
                  value={userProfileData.organization.parent || ''}
                  className="yourProfile__inputBackground"
                />
              </div>
            </div>
            {/** ********************************************** */}
            {/**  PHONE NUMBER / STATE */}
            <div className="row mt-2">
              <div className="col-12 col-md-6">
                <p className="yourProfile__title">
                  {t('profile.organizationInformation.phoneNumber')}
                </p>
                <Input
                  value={userProfileData.organization.phoneNumber || 0}
                  className="yourProfile__inputBackground"
                />
              </div>
              <div className="col-12 col-md-6">
                <p className="yourProfile__title">
                  {t('profile.organizationInformation.state')}
                </p>
                <Input
                  value={userProfileData.organization.state || ''}
                  className="yourProfile__inputBackground"
                />
              </div>
            </div>
            {/** ********************************************** */}
            {/**  TYPE  */}
            <div className="row mt-2">
              <div className="col-12 col-md-6">
                <p className="yourProfile__title">
                  {t('profile.organizationInformation.type')}
                </p>
                <Input
                  value={userProfileData.organization.type || ''}
                  className="yourProfile__inputBackground"
                />
              </div>
            </div>
          </div>
        </>
      )}
      <br />
      <br />
      {/* ************************************************** */}
      {/* ROLE DATA **************************************** */}
      {userProfileData && userProfileData.role && (
        <>
          <Divider orientation="left" plain>
            <FontAwesomeIcon
              className="generalStyles__infoIcon "
              icon={faScrewdriverWrench}
            />
            <span className="yourProfile__dividerTitle yourProfile__title yourProfile__title">
              {t('profile.roleInformation.roleInformation')}
            </span>
          </Divider>
          <div className="container yourProfile__inputContainer generalStyles__boxShadow">
            {/** ********************************************** */}
            {/**  NAME / TYPE */}
            <div className="row mt-2">
              <div className="col-12 col-md-6">
                <p className="yourProfile__title">
                  {t('profile.roleInformation.name')}
                </p>
                <Input
                  value={userProfileData.role.name || ''}
                  className="yourProfile__inputBackground"
                />
              </div>
              <div className="col-12 col-md-6">
                <p className="yourProfile__title">
                  {t('profile.roleInformation.type')}
                </p>
                <Input
                  value={userProfileData.role.type || ''}
                  className="yourProfile__inputBackground"
                />
              </div>
            </div>
          </div>
        </>
      )}
      <br />
      <br />
    </div>
  )
}

export default YourProfile
