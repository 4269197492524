// REDUX
import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
// REDUCERS / SLICES
import homeReducer from '../redux/home/homeSlice';
import loginReducer from '../redux/login/loginSlice';
import organizationReducer from '../redux/organizations/organizationSlice';
import userReducer from '../redux/users/userSlice';
import roleReduer from '../redux/roles/roleSlice';
// API
import { countriesStatesCitiesAPI } from '../redux/countriesStatesCitiesAPI';
import { organizationAPI } from '../redux/organizations/organizationAPI';
import { userAPI } from '../redux/users/userAPI';
import { roleAPI } from '../redux/roles/roleAPI';

export const store = configureStore({
  reducer: {
    home: homeReducer,
    login: loginReducer,
    organization: organizationReducer,
    user: userReducer,
    role: roleReduer,
    // API REDUCERS
    [organizationAPI.reducerPath]: organizationAPI.reducer,
    [userAPI.reducerPath]: userAPI.reducer,
    [countriesStatesCitiesAPI.reducerPath]: countriesStatesCitiesAPI.reducer,
    [roleAPI.reducerPath]: roleAPI.reducer,
  },
  // Adding the api middleware enables caching, invalidation, polling
  // and other useful features off rtk-query
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }).concat(
      organizationAPI.middleware,
      userAPI.middleware,
      roleAPI.middleware
    ),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
// export type RootState = store.getState
// eslint-disable-next-line no-undef
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

setupListeners(store.dispatch);
