/* eslint-disable no-use-before-define */
import React, { useEffect, useState } from 'react'
// ANT DESIGN COMPONENTS
import { Table, Alert, Menu, Dropdown, Space, Tag } from 'antd'
// I18N TRANSLATION
import { useTranslation } from 'react-i18next'
// FONT AWESOME LIBRYARY AND ICONS
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faEllipsis,
  faTrash,
  faPenToSquare,
  faKey,
  faLock,
  faUnlock,
  faCheckCircle,
  faCircleExclamation,
  faBan,
} from '@fortawesome/free-solid-svg-icons'
// REDUX
import { useDispatch, useSelector } from 'react-redux'
import { useLazyGetUsersQuery } from '../../redux/users/userAPI'
import {
  updateOpenModalDeleteUser,
  updateOpenModalEditUser,
  updateOpenModalResetUserPassword,
  updateOpenDrawerUser,
  updateUser,
  updateUserTableParams,
  updateUserList,
} from '../../redux/users/userSlice'
// AUTHORIZATION
import GETJwtToken from '../../redux/authentication'

library.add(
  faEllipsis,
  faTrash,
  faPenToSquare,
  faKey,
  faLock,
  faUnlock,
  faCheckCircle,
  faCircleExclamation,
  faBan,
)

const UsersTable = () => {
  // ************************************************ */
  // LOCAL STORAGE AND VARIABLES ******************** */
  const dispatch = useDispatch()
  const [users, setUsers] = useState<any[]>([])
  const [selectedItem, setSelectedItem] = useState<any>({})
  const [t] = useTranslation('global')
  const [
    triggerGetUsersTable,
    { data, isLoading, isError, isFetching },
  ] = useLazyGetUsersQuery()
  const { userTableParams, userRefreshTable } = useSelector(
    (state: any) => state.user,
  )
  const { Column } = Table

  const menu = (
    <Menu
      items={[
        {
          label: (
            <>
              {selectedItem.type !== 'MASTER' ? (
                <div
                  className="generalStyles__tableActionBox"
                  role="button"
                  tabIndex={0}
                  onClick={() => {
                    handleOpenModalEdit()
                  }}
                  onKeyDown={() => {
                    handleOpenModalEdit()
                  }}
                >
                  <span>
                    <FontAwesomeIcon
                      className="generalStyles__info"
                      icon={faPenToSquare}
                      style={{ marginRight: '10px' }}
                    />
                    {t('userManagement.edit')}
                  </span>
                </div>
              ) : (
                <></>
              )}
            </>
          ),
          key: '0',
        },
        {
          label: (
            <>
              {selectedItem.type !== 'MASTER' ? (
                <div
                  className="generalStyles__tableActionBox"
                  role="button"
                  tabIndex={0}
                  onClick={() => {
                    handleOpenModalDelete()
                  }}
                  onKeyDown={() => {
                    handleOpenModalDelete()
                  }}
                >
                  <span>
                    {selectedItem.status === 'ACTIVE' ? (
                      <FontAwesomeIcon
                        className="generalStyles__info"
                        icon={faLock}
                        style={{ marginRight: '10px' }}
                      />
                    ) : (
                      <FontAwesomeIcon
                        className="generalStyles__info"
                        icon={faUnlock}
                        style={{ marginRight: '10px' }}
                      />
                    )}
                    {t('userManagement.unBlock')}
                  </span>
                </div>
              ) : (
                <></>
              )}
            </>
          ),
          key: '2',
        },
        {
          label: (
            <>
              {selectedItem.type !== 'MASTER' ? (
                <div
                  className="generalStyles__tableActionBox"
                  role="button"
                  tabIndex={0}
                  onClick={() => {
                    handleOpenModalResetUserPassword()
                  }}
                  onKeyDown={() => {
                    handleOpenModalResetUserPassword()
                  }}
                >
                  <span>
                    <FontAwesomeIcon
                      className="generalStyles__info"
                      icon={faKey}
                      style={{ marginRight: '10px' }}
                    />
                    {t('userManagement.resetPassword')}
                  </span>
                </div>
              ) : (
                <></>
              )}
            </>
          ),
          key: '1',
        },
        {
          label: (
            <>
              {selectedItem.type !== 'MASTER' ? (
                <div
                  className="generalStyles__tableActionBox"
                  role="button"
                  tabIndex={0}
                  onClick={() => {
                    handleOpenModalDelete()
                  }}
                  onKeyDown={() => {
                    handleOpenModalDelete()
                  }}
                >
                  <span>
                    <FontAwesomeIcon
                      className="generalStyles__info"
                      icon={faTrash}
                      style={{ marginRight: '10px' }}
                    />
                    {t('userManagement.delete')}
                  </span>
                </div>
              ) : (
                <></>
              )}
            </>
          ),
          key: '3',
        },
      ]}
    />
  )

  // ************************************************ */
  // FUNCTIONS ************************************** */

  const handleOpenModalEdit = () => {
    dispatch(updateUser(selectedItem))
    dispatch(updateOpenModalEditUser(true))
  }

  const handleOpenModalDelete = () => {
    dispatch(updateUser(selectedItem))
    dispatch(updateOpenModalDeleteUser(true))
  }

  const handleOpenModalResetUserPassword = () => {
    dispatch(updateUser(selectedItem))
    dispatch(updateOpenModalResetUserPassword(true))
  }

  const onCellClick = (record: any) => {
    if (record) {
      dispatch(updateUser(record))
      dispatch(updateOpenDrawerUser(true))
    }
  }

  const handleTableChange = async (
    pagination: any,
    filters: any,
    sorter: any,
  ) => {
    dispatch(
      updateUserTableParams({
        pagination,
        filters,
        ...sorter,
      }),
    )
    //
    const token = await GETJwtToken()
    triggerGetUsersTable({
      page: pagination.current,
      limit: pagination.pageSize,
      token,
    })
  }

  const refreshTable = async () => {
    dispatch(
      updateUserTableParams({
        pagination: {
          current: 1,
          pageSize: 10,
          total: userTableParams.pagination.total,
        },
        filters: {},
        sorter: {},
      }),
    )
    //
    const token = await GETJwtToken()
    triggerGetUsersTable({
      page: 1,
      limit: 10,
      token,
    })
  }

  const getInitialData = async () => {
    const token = await GETJwtToken()
    triggerGetUsersTable({
      page: userTableParams.pagination.current,
      limit: userTableParams.pagination.pageSize,
      token,
    })
  }

  // ************************************************* */
  // USE EFFECT ************************************** */
  useEffect(() => {
    getInitialData()
  }, [])

  useEffect(() => {
    if (data && data.data && data.data.length > 0) {
      dispatch(
        updateUserTableParams({
          ...userTableParams,
          pagination: {
            ...userTableParams.pagination,
            total: data.total,
          },
        }),
      )
      setUsers(data.data)
      dispatch(updateUserList(data.data))
    }
  }, [data])

  useEffect(() => {
    if (isError) {
      setUsers([])
    }
  }, [isError])

  useEffect(() => {
    if (userRefreshTable) {
      refreshTable()
    }
  }, [userRefreshTable])

  // ************************************************ */
  // COMPONENT ************************************** */
  return (
    <div className="mt-3">
      {!isError ? (
        <Table
          rowClassName={(record, index) =>
            index % 2 === 0
              ? 'table-row-light custom-row-hover'
              : 'table-row-dark custom-row-hover'
          }
          className="customTable"
          dataSource={users}
          loading={isLoading || isFetching}
          rowKey={(record) => record.id}
          size="middle"
          pagination={userTableParams.pagination}
          onChange={handleTableChange}
        >
          <Column
            onCell={(record) => ({
              onClick: () => {
                onCellClick(record)
              },
            })}
            title={t('userManagement.name')}
            dataIndex="first_name"
            key="first_name"
            className="table__header"
            width="200px"
            filterSearch
            render={(text, record: any) => (
              <>
                <div className="table__ellipsis__200px">
                  {record.first_name} {record.middle_name} {record.last_name}
                </div>
              </>
            )}
          />
          <Column
            onCell={(record) => ({
              onClick: () => {
                onCellClick(record)
              },
            })}
            title={t('userManagement.email')}
            dataIndex="email"
            key="email"
            className="table__header"
            width="200px"
            filterSearch
            render={(text) => (
              <>
                <div className="table__ellipsis__200px">{text}</div>
              </>
            )}
          />
          <Column
            onCell={(record) => ({
              onClick: () => {
                onCellClick(record)
              },
            })}
            title={t('userManagement.role')}
            dataIndex="role"
            key="role"
            className="table__header"
            width="200px"
            filterSearch
            render={(text) => (
              <>
                <div className="table__ellipsis__200px">
                  {text.name || '--'}
                </div>
              </>
            )}
          />
          <Column
            onCell={(record) => ({
              onClick: () => {
                onCellClick(record)
              },
            })}
            title={t('userManagement.status')}
            dataIndex="status"
            key="status"
            className="table__header"
            width="200px"
            filterSearch
            render={(text) => (
              <>
                <div className="table__ellipsis__200px">
                  {text === 'ACTIVE' && (
                    <Tag
                      color="success"
                      icon={
                        <FontAwesomeIcon
                          icon={faCheckCircle}
                          style={{ marginRight: '5px' }}
                        />
                      }
                      style={{ width: '90px' }}
                    >
                      Active
                    </Tag>
                  )}
                  {text === 'NEW' && (
                    <Tag
                      color="warning"
                      icon={
                        <FontAwesomeIcon
                          icon={faCircleExclamation}
                          style={{ marginRight: '5px' }}
                        />
                      }
                      style={{ width: '90px' }}
                    >
                      New user
                    </Tag>
                  )}
                  {text === 'INACTIVE' && (
                    <Tag
                      color="error"
                      icon={
                        <FontAwesomeIcon
                          icon={faBan}
                          style={{ marginRight: '5px' }}
                        />
                      }
                      style={{ width: '90px' }}
                    >
                      Inactive
                    </Tag>
                  )}{' '}
                </div>
              </>
            )}
          />
          <Column
            title={t('userManagement.actions')}
            key="action"
            width="50px"
            render={(_: any, record: any) => (
              <Dropdown overlay={menu} trigger={['click']}>
                <Space
                  className="generalStyles__hoverCursor"
                  onClick={() => {
                    setSelectedItem(record)
                  }}
                >
                  <FontAwesomeIcon icon={faEllipsis} />
                </Space>
              </Dropdown>
            )}
          />
        </Table>
      ) : (
        <Alert
          message={t('userManagement.errorDescription.error')}
          description={t('userManagement.errorDescription.text1')}
          type="error"
          showIcon
          className="mt-3"
        />
      )}
    </div>
  )
}

export default UsersTable
