import { Route, Routes, Link } from 'react-router-dom'
import React, { useState } from 'react'
// ANT DESIGN COMPONENTS
import { Layout, Menu } from 'antd'
// I18N TRANSLATION
import { useTranslation } from 'react-i18next'
// FONT AWESOME LIBRYARY AND ICONS
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faMoon,
  faSun,
  faBuilding,
  faBuildingUser,
  faUsers,
  faUser,
  faUserLock,
} from '@fortawesome/free-solid-svg-icons'
// REDUX
import { useSelector, useDispatch } from 'react-redux'
import { updateBreadcrumbTitle } from '../../redux/home/homeSlice'
// COMPONENTS
import LayoutBreadcrumb from './LayoutBreadcrumb'
import UsersPage from '../userManagement/UsersPage'
import OrganizationsPage from '../organizationManagement/OrganizationsPage'
import RolesPage from '../roles/RolesPage'
import YourProfilePage from './YourProfilePage'
import YourProfileDrawer from './YourProfileDrawer'
import GlobalAlert from './GlobalAlert'
// STYLES
import logoBlack from '../../assets/black-bg-lnlogo.png'
import logoWhite from '../../assets/white-bg-lnlogo.png'

const { Header, Content, Footer, Sider } = Layout
library.add(
  faMoon,
  faSun,
  faBuilding,
  faUsers,
  faUser,
  faBuildingUser,
  faUserLock,
)

const App = () => {
  // ************************************************ */
  // LOCAL STORAGE AND VARIABLES ******************** */
  const dispatch = useDispatch()
  const [collapsed, setCollapsed] = useState<boolean>(false)
  const [t] = useTranslation('global')
  const { theme } = useSelector((state: any) => state.home)
  // ************************************************* */
  // FUNCTIONS ************************************** */
  const handleChangeHeaderTitle = (title: string) => {
    localStorage.setItem('headerTitle', title)
    dispatch(updateBreadcrumbTitle(title))
  }

  // ************************************************* */
  // USE EFFECT ************************************** */

  // ************************************************ */
  // COMPONENT ************************************** */

  return (
    <Layout
      style={{
        minHeight: '100vh',
      }}
    >
      {/** ******************************************************* */}
      {/** SIDE BAR */}
      <Sider
        collapsible
        collapsed={collapsed}
        onCollapse={(value) => setCollapsed(value)}
        width="290"
      >
        <div className="logo">
          <img
            src={theme === 'light' ? logoWhite : logoBlack}
            alt=""
            className="layout__sideBarLogo"
          />
        </div>
        <Menu defaultSelectedKeys={['1']} mode="inline">
          <Menu.SubMenu
            title="Organization Management"
            key={1}
            icon={
              <FontAwesomeIcon
                className="generalStyles__info"
                icon={faBuildingUser}
              />
            }
          >
            <Menu.Item
              key={2}
              icon={
                <FontAwesomeIcon
                  className="generalStyles__info"
                  icon={faBuilding}
                />
              }
            >
              <Link
                onClick={() => {
                  handleChangeHeaderTitle('organizations')
                }}
                to="/organization-management/organizations"
              >
                <span>{t('sideBar.organizations')}</span>
              </Link>
            </Menu.Item>
            <Menu.Item
              key={5}
              icon={
                <FontAwesomeIcon
                  className="generalStyles__info"
                  icon={faUserLock}
                />
              }
            >
              <Link
                onClick={() => {
                  handleChangeHeaderTitle('roles')
                }}
                to="/organization-management/roles"
              >
                <span>{t('sideBar.roles')}</span>
              </Link>
            </Menu.Item>
          </Menu.SubMenu>
          <Menu.SubMenu
            title="User Management"
            key={3}
            icon={
              <FontAwesomeIcon className="generalStyles__info" icon={faUser} />
            }
          >
            <Menu.Item
              key={4}
              icon={
                <FontAwesomeIcon
                  className="generalStyles__info"
                  icon={faUsers}
                />
              }
            >
              <Link
                onClick={() => {
                  handleChangeHeaderTitle('users')
                }}
                to="/users-management/users"
              >
                <span>{t('sideBar.users')}</span>
              </Link>
            </Menu.Item>
          </Menu.SubMenu>
        </Menu>
      </Sider>
      {/** ******************************************************* */}
      {/** LAYOUT */}
      <Layout>
        {/** ******************************************************* */}
        {/** HEADER */}
        <Header style={{ padding: 0, height: '72px' }}>
          <div className="generalStyles__spaceBetween">
            <div />
            <div>
              <YourProfileDrawer />
            </div>
          </div>
        </Header>
        {/** ******************************************************* */}
        {/** CONTENT */}
        <Content>
          <LayoutBreadcrumb />
          <div
            className={`layout__contentPage ${
              theme === 'light'
                ? 'layout__contentPage__light'
                : 'layout__contentPage__dark'
            }`}
          >
            <Routes>
              <Route path="users" element={<UsersPage />} />
              <Route path="organizations" element={<OrganizationsPage />} />
              <Route path="roles" element={<RolesPage />} />
              <Route path="your-profile" element={<YourProfilePage />} />
            </Routes>
            <GlobalAlert />
          </div>
        </Content>
        {/** ******************************************************* */}
        {/** FOOTER */}
        <Footer className="layout__footerText">
          <span>© {new Date().getFullYear()} powered by - LiftNet</span>
        </Footer>
      </Layout>
    </Layout>
  )
}

export default App
