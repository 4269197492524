import React from 'react'
// ANT DESIGN COMPONENTS
import { Button } from 'antd'
// FONT AWESOME LIBRYARY AND ICONS
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faArrowsRotate } from '@fortawesome/free-solid-svg-icons'
// I18N TRANSLATION
import { useTranslation } from 'react-i18next'
// REDUX
import { useDispatch } from 'react-redux'
import {
  updateOpenModalCreateNewRole,
  updateRoleRefreshTable,
} from '../../redux/roles/roleSlice'

library.add(faPlus, faArrowsRotate)

function RolesHeader() {
  // ************************************************ */
  // LOCAL STORAGE AND VARIABLES ******************** */
  const dispatch = useDispatch()
  const [t] = useTranslation('global')

  // ************************************************ */
  // FUNCTIONS ************************************** */
  const handleOpenModalCreateNewRole = () => {
    dispatch(updateOpenModalCreateNewRole(true))
  }

  const handleReloadPage = () => {
    dispatch(updateRoleRefreshTable(true))
    setTimeout(() => {
      dispatch(updateRoleRefreshTable(false))
    }, 1000)
  }

  return (
    <div style={{ display: 'flex', flexWrap: 'wrap' }}>
      <div style={{ marginLeft: '10px', marginTop: '5px' }}>
        <Button
          type="primary"
          icon={
            <FontAwesomeIcon icon={faPlus} style={{ marginRight: '10px' }} />
          }
          onClick={handleOpenModalCreateNewRole}
        >
          {t('organizationManagement.createNewRole')}
        </Button>
      </div>
      <div style={{ marginLeft: '10px', marginTop: '5px' }}>
        <Button
          type="primary"
          icon={
            <FontAwesomeIcon
              icon={faArrowsRotate}
              style={{ marginRight: '10px' }}
            />
          }
          onClick={() => {
            handleReloadPage()
          }}
        >
          {t('organizationManagement.refreshTable')}
        </Button>
      </div>
    </div>
  )
}

export default RolesHeader
