import React from 'react'
// ANT DESIGN COMPONENTS
import { Carousel } from 'antd'
// STYLES
import c1 from '../../assets/carousel1.jpg'
import c2 from '../../assets/carousel2.jpg'
import c3 from '../../assets/carousel3.jpg'
import c4 from '../../assets/carousel4.jpg'
import logo from '../../assets/black-bg-lnlogo.png'

function CustomAmplifyCarousel() {
  return (
    <div className="loginStyle__containerOver">
      <div className="loginStyle__boxOver loginStyle__overlayOver">
        <img src={logo} className="loginStyles__liftNetLogoCarousel" alt="" />
      </div>
      <div className="loginStyle__boxOver ">
        <Carousel autoplay>
          <div className="loginStyles__carouselSubBox">
            <div>
              <img src={c1} alt="" className="loginStyles__carouselImage" />
            </div>
          </div>
          <div>
            <div>
              <img src={c2} alt="" className="loginStyles__carouselImage" />
            </div>
          </div>
          <div>
            <div>
              <img src={c3} alt="" className="loginStyles__carouselImage" />
            </div>
          </div>
          <div>
            <div>
              <img src={c4} alt="" className="loginStyles__carouselImage" />
            </div>
          </div>
        </Carousel>
      </div>
    </div>
  )
}

export default CustomAmplifyCarousel
