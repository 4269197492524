import { createApi, fetchBaseQuery, retry } from '@reduxjs/toolkit/query/react';

const maxRetries = 2;

const baseQuery = retry(
  fetchBaseQuery({
    baseUrl: process.env.REACT_APP_LIFT_NET_CLOUD_API,
  }),
  { maxRetries }
);

export const userAPI = createApi({
  reducerPath: 'userAPI',
  baseQuery,
  keepUnusedDataFor: 0,
  tagTypes: ['tagGetUsers'],
  refetchOnMountOrArgChange: true,
  refetchOnReconnect: true,
  endpoints: (builder) => ({
    //* *********************************************************** */
    //* GET ALL USERS */
    getUsers: builder.query({
      query: (body: any) => ({
        url: `/users/?page=${body.page}&limit=${body.limit}`,
        headers: { authorization: body.token },
      }),
      providesTags: ['tagGetUsers'],
    }),
    //* *********************************************************** */
    //* CREATE USER */
    postUser: builder.mutation({
      query: (body: any) => ({
        url: '/users/',
        method: 'post',
        body: body.body,
        headers: { authorization: body.token },
      }),
      invalidatesTags: ['tagGetUsers'],
    }),
    //* *********************************************************** */
    //* EDIT USER */
    putUser: builder.mutation({
      query: (body: any) => ({
        url: `/users/${body.id}`,
        method: 'put',
        body: body.body,
        headers: { authorization: body.token },
      }),
      invalidatesTags: ['tagGetUsers'],
    }),
    //* *********************************************************** */
    //* DELETE USER */
    deleteUser: builder.mutation({
      query: (body: any) => ({
        url: `/users/${body.id}`,
        method: 'delete',
        headers: { authorization: body.token },
      }),
      invalidatesTags: ['tagGetUsers'],
    }),
    //* *********************************************************** */
    //* RESET COGNITO USER PASSWORD */
    resetUserPassword: builder.mutation({
      query: (body: any) => ({
        url: `/users/reset/${body.id}`,
        method: 'put',
        headers: { authorization: body.token },
      }),
      invalidatesTags: ['tagGetUsers'],
    }),
    //* *********************************************************** */
    //* BLOCK USER */
    blockUser: builder.mutation({
      query: (body: any) => ({
        url: `/users/block/${body.id}`,
        method: 'put',
        headers: { authorization: body.token },
      }),
      invalidatesTags: ['tagGetUsers'],
    }),
    //* *********************************************************** */
    //* UN BLOCK USER */
    unBlockUser: builder.mutation({
      query: (body: any) => ({
        url: `/users/unblock/${body.id}`,
        method: 'put',
        headers: { authorization: body.token },
      }),
      invalidatesTags: ['tagGetUsers'],
    }),
    //* *********************************************************** */
  }),
});

export const {
  useLazyGetUsersQuery,
  usePostUserMutation,
  usePutUserMutation,
  useDeleteUserMutation,
  useResetUserPasswordMutation,
  useBlockUserMutation,
  useUnBlockUserMutation,
} = userAPI;
