/* eslint-disable no-use-before-define */
import React, { useEffect, useState } from 'react'
// ANT DESIGN COMPONENTS
import { Table, Alert, Menu, Dropdown, Space, Tag } from 'antd'
// I18N TRANSLATION
import { useTranslation } from 'react-i18next'
// FONT AWESOME LIBRYARY AND ICONS
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faEllipsis,
  faTrash,
  faPenToSquare,
  faKey,
  faLock,
  faUnlock,
  faCheckCircle,
  faCircleExclamation,
  faBan,
} from '@fortawesome/free-solid-svg-icons'
// REDUX
import { useDispatch, useSelector } from 'react-redux'
import { useLazyGetRolesQuery } from '../../redux/roles/roleAPI'
import {
  updateOpenModalDeleteRole,
  updateOpenDrawerRole,
  updateRole,
  updateRoleTableParams,
} from '../../redux/roles/roleSlice'
// AUTHORIZATION
import GETJwtToken from '../../redux/authentication'

library.add(
  faEllipsis,
  faTrash,
  faPenToSquare,
  faKey,
  faLock,
  faUnlock,
  faCheckCircle,
  faCircleExclamation,
  faBan,
)

const RolesTable = () => {
  // ************************************************ */
  // LOCAL STORAGE AND VARIABLES ******************** */
  const dispatch = useDispatch()
  const [roles, setRoles] = useState<any[]>([])
  const [selectedItem, setSelectedItem] = useState<any>({})
  const [t] = useTranslation('global')
  const [
    triggerGetRolesTable,
    { data, isLoading, isError, isFetching },
  ] = useLazyGetRolesQuery()
  const { roleTableParams, roleRefreshTable } = useSelector(
    (state: any) => state.role,
  )
  const { Column } = Table

  const menu = (
    <Menu
      items={[
        {
          label: (
            <>
              {selectedItem.name !== 'MASTER ADMIN' ? (
                <div
                  className="generalStyles__tableActionBox"
                  role="button"
                  tabIndex={0}
                  onClick={() => {
                    handleOpenModalDelete()
                  }}
                  onKeyDown={() => {
                    handleOpenModalDelete()
                  }}
                >
                  <span>
                    <FontAwesomeIcon
                      className="generalStyles__info"
                      icon={faTrash}
                      style={{ marginRight: '10px' }}
                    />
                    {t('organizationManagement.delete')}
                  </span>
                </div>
              ) : (
                <></>
              )}
            </>
          ),
          key: '0',
        },
      ]}
    />
  )

  // ************************************************ */
  // FUNCTIONS ************************************** */
  const handleOpenModalDelete = () => {
    dispatch(updateRole(selectedItem))
    dispatch(updateOpenModalDeleteRole(true))
  }

  const onCellClick = (record: any) => {
    if (record) {
      dispatch(updateRole(record))
      dispatch(updateOpenDrawerRole(true))
    }
  }

  const handleTableChange = async (
    pagination: any,
    filters: any,
    sorter: any,
  ) => {
    dispatch(
      updateRoleTableParams({
        pagination,
        filters,
        ...sorter,
      }),
    )
    //
    const token = await GETJwtToken()
    triggerGetRolesTable({
      page: pagination.current,
      limit: pagination.pageSize,
      token,
    })
  }

  const refreshTable = async () => {
    dispatch(
      updateRoleTableParams({
        pagination: {
          current: 1,
          pageSize: 10,
          total: roleTableParams.pagination.total,
        },
        filters: {},
        sorter: {},
      }),
    )
    //
    const token = await GETJwtToken()
    triggerGetRolesTable({
      page: 1,
      limit: 10,
      token,
    })
  }

  const getInitialData = async () => {
    const token = await GETJwtToken()
    triggerGetRolesTable({
      page: roleTableParams.pagination.current,
      limit: roleTableParams.pagination.pageSize,
      token,
    })
  }

  // ************************************************* */
  // USE EFFECT ************************************** */
  useEffect(() => {
    getInitialData()
  }, [])

  useEffect(() => {
    if (data && data.data && data.data.length > 0) {
      dispatch(
        updateRoleTableParams({
          ...roleTableParams,
          pagination: {
            ...roleTableParams.pagination,
            total: data.total,
          },
        }),
      )
      setRoles(data.data)
    }
  }, [data])

  useEffect(() => {
    if (isError) {
      setRoles([])
    }
  }, [isError])

  useEffect(() => {
    if (roleRefreshTable) {
      refreshTable()
    }
  }, [roleRefreshTable])

  // ************************************************ */
  // COMPONENT ************************************** */
  return (
    <div className="mt-3">
      {!isError ? (
        <Table
          rowClassName={(record, index) =>
            index % 2 === 0
              ? 'table-row-light custom-row-hover'
              : 'table-row-dark custom-row-hover'
          }
          className="customTable"
          dataSource={roles}
          loading={isLoading || isFetching}
          rowKey={(record) => record.id}
          size="middle"
          pagination={roleTableParams.pagination}
          onChange={handleTableChange}
        >
          <Column
            onCell={(record) => ({
              onClick: () => {
                onCellClick(record)
              },
            })}
            title={t('organizationManagement.name')}
            dataIndex="name"
            key="name"
            className="table__header"
            width="200px"
            filterSearch
            render={(text) => (
              <>
                <div className="table__ellipsis__200px">{text}</div>
              </>
            )}
          />
          <Column
            onCell={(record) => ({
              onClick: () => {
                onCellClick(record)
              },
            })}
            title={t('organizationManagement.status')}
            dataIndex="status"
            key="status"
            className="table__header"
            width="200px"
            filterSearch
            render={(text, record: any) => (
              <>
                <div className="table__ellipsis__200px">
                  {record.active ? (
                    <Tag
                      color="success"
                      icon={
                        <FontAwesomeIcon
                          icon={faCheckCircle}
                          style={{ marginRight: '5px' }}
                        />
                      }
                      style={{ width: '90px' }}
                    >
                      {t('organizationManagement.active')}
                    </Tag>
                  ) : (
                    <Tag
                      color="error"
                      icon={
                        <FontAwesomeIcon
                          icon={faBan}
                          style={{ marginRight: '5px' }}
                        />
                      }
                      style={{ width: '90px' }}
                    >
                      {t('organizationManagement.locked')}
                    </Tag>
                  )}
                </div>
              </>
            )}
          />
          <Column
            title={t('organizationManagement.actions')}
            key="action"
            width="50px"
            render={(_: any, record: any) => (
              <Dropdown overlay={menu} trigger={['click']}>
                <Space
                  className="generalStyles__hoverCursor"
                  onClick={() => {
                    setSelectedItem(record)
                  }}
                >
                  <FontAwesomeIcon icon={faEllipsis} />
                </Space>
              </Dropdown>
            )}
          />
        </Table>
      ) : (
        <Alert
          message={t('organizationManagement.errorDescription.error')}
          description={t('organizationManagement.errorDescription.text1')}
          type="error"
          showIcon
          className="mt-3"
        />
      )}
    </div>
  )
}

export default RolesTable
