import React from 'react'
// ANT DESIGN COMPONENTS
import { Breadcrumb } from 'antd'
// I18N TRANSLATION
import { useTranslation } from 'react-i18next'
// FONT AWESOME LIBRYARY AND ICONS
import { library } from '@fortawesome/fontawesome-svg-core'
import { faUser } from '@fortawesome/free-solid-svg-icons'
// REDUX
import { useSelector } from 'react-redux'
// STYLES

library.add(faUser)

function LayoutBreadcrumb() {
  // ************************************************ */
  // LOCAL STORAGE AND VARIABLES ******************** */
  const [t] = useTranslation('global')
  const { breadcrumbTitle } = useSelector((state: any) => state.home)
  // ************************************************ */
  // FUNCTIONS ************************************** */

  // ************************************************* */
  // USE EFFECT ************************************** */

  // ************************************************ */
  // COMPONENT ************************************** */
  return (
    <Breadcrumb style={{ margin: '20px 0 0 20px' }}>
      {(localStorage.getItem('headerTitle') === 'organizations' ||
        breadcrumbTitle === 'organizations') && (
        <>
          <Breadcrumb.Item>
            {t('header.organizationManagement')}
          </Breadcrumb.Item>
          <Breadcrumb.Item>{t('header.organizations')}</Breadcrumb.Item>
        </>
      )}
      {(localStorage.getItem('headerTitle') === 'users' ||
        breadcrumbTitle === 'users') && (
        <>
          <Breadcrumb.Item>{t('header.userManagement')}</Breadcrumb.Item>
          <Breadcrumb.Item>{t('header.users')}</Breadcrumb.Item>
        </>
      )}
      {(localStorage.getItem('headerTitle') === 'roles' ||
        breadcrumbTitle === 'roles') && (
        <>
          <Breadcrumb.Item>
            {t('header.organizationManagement')}
          </Breadcrumb.Item>
          <Breadcrumb.Item>{t('header.roles')}</Breadcrumb.Item>
        </>
      )}
    </Breadcrumb>
  )
}

export default LayoutBreadcrumb
