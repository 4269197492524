import React from 'react'
// ANT DESIGN COMPONENT
import type { RadioChangeEvent } from 'antd'
import { Radio, Space, Divider } from 'antd'
// I18N TRANSLATION
import { useTranslation } from 'react-i18next'
// FONT AWESOME LIBRYARY AND ICONS
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLanguage } from '@fortawesome/free-solid-svg-icons'
// REDUX
import { useDispatch } from 'react-redux'
import { updateLanguage } from '../../redux/home/homeSlice'
// STYLES
import usaFlag from '../../assets/unitedStatesFlag.png'
import spainFlag from '../../assets/spainFlag.png'

library.add(faLanguage)

function Internationalization() {
  // ************************************************ */
  // LOCAL STORAGE AND VARIABLES ******************** */
  const dispatch = useDispatch()
  const [t, i18n] = useTranslation('global')

  // ************************************************* */
  // FUNCTIONS ************************************** */
  const onChangeLanguage = (e: RadioChangeEvent) => {
    i18n.changeLanguage(e.target.value)
    dispatch(updateLanguage(e.target.value))
  }

  // ************************************************* */
  // COMPONENT ************************************** */
  return (
    <div>
      {/** ************************************************* */}
      <div>
        <h5>
          <p className="generalStyles__text">
            <FontAwesomeIcon
              icon={faLanguage}
              className="generalStyles__info"
              style={{ marginRight: '10px' }}
            />
            {t('profile.language.applicationLanguage')}
          </p>
        </h5>
        <Divider />
      </div>
      {/* -*-*-*-*-*-*-*-*-*-*-*-*-*-*- */}
      <div className="generalStyles__flex">
        <div className="yourProfile__drawerContainerText1">
          <p className="drawer__title">{t('profile.language.language')}:</p>
        </div>
        <div className="yourProfile__drawerContainerText2">
          <p className="generalStyles__text">
            <Radio.Group onChange={onChangeLanguage} value={i18n.language}>
              <Space>
                <Radio value="en">
                  <img src={usaFlag} alt="" width="25" height="15" />
                </Radio>
                <Radio value="es">
                  <img src={spainFlag} alt="" width="25" height="15" />
                </Radio>
              </Space>
            </Radio.Group>
          </p>
        </div>
      </div>
      <br />
      <br />
    </div>
  )
}

export default Internationalization
