import React, { useEffect, useState } from 'react'
// ANT DESIGN COMPONENTS
import {
  Drawer,
  Button,
  Form,
  Input,
  Select,
  Spin,
  Divider,
  InputNumber,
} from 'antd'
// FONT AWESOME LIBRYARY AND ICONS
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBuildingCircleExclamation } from '@fortawesome/free-solid-svg-icons'
// I18N TRANSLATION
import { useTranslation } from 'react-i18next'
// REDUX
import { useDispatch, useSelector } from 'react-redux'
import { usePutUserMutation } from '../../redux/users/userAPI'
import { useLazyGetRolesQuery } from '../../redux/roles/roleAPI'
import {
  updateOpenModalEditUser,
  updateUserAlert,
} from '../../redux/users/userSlice'
// AUTHORIZATION
import GETJwtToken from '../../redux/authentication'

const { Option } = Select
library.add(faBuildingCircleExclamation)

function UsersEditUserForm() {
  // ************************************************ */
  // LOCAL STORAGE AND VARIABLES ******************** */
  const dispatch = useDispatch()
  const { user, openModalEditUser } = useSelector((state: any) => state.user)
  const [t] = useTranslation('global')
  const [listRoles, setListRoles] = useState<any[]>([])
  const [triggerGetRoles, { data: dataGetRoles }] = useLazyGetRolesQuery()
  //
  const [form] = Form.useForm()
  //
  const [
    putUser,
    {
      isSuccess: isSuccessPutUser,
      isError: isErrorPutUser,
      isLoading: isLoadingPutUser,
      reset: resetPutUser,
    },
  ] = usePutUserMutation()

  // ************************************************ */
  // FUNCTIONS ************************************** */
  const onReset = () => {
    form.resetFields()
  }

  const handleCloseModal = () => {
    dispatch(updateOpenModalEditUser(false))
  }

  const onFinish = async (values: any) => {
    const token = await GETJwtToken()
    const BODY = {
      id: user.id,
      body: {
        first_name: values.firstName,
        middle_name: values.middleName,
        last_name: values.lastName,
        org_id: user.org_id,
        role_id: values.role,
        user_org_server: '',
      },
      token,
    }
    putUser(BODY)
  }

  const onClose = () => {
    onReset()
    dispatch(updateOpenModalEditUser(false))
  }

  const onFill = () => {
    if (user) {
      form.setFieldsValue({
        firstName: user.first_name || '',
        middleName: user.middle_name || '',
        lastName: user.last_name || '',
        email: user.email || '',
        phone: parseInt(user.contact_info[0].phone, 10) || '',
        dialCode: parseInt(user.contact_info[0].phone_code, 10) || '',
        role: user.role_id || '',
        organization: user.org.name || '',
        address: user.contact_info[0].address || '',
      })
    }
  }

  const launchGetRoles = async () => {
    const token = await GETJwtToken()
    triggerGetRoles({ token })
  }

  // ************************************************* */
  // USE EFFECT ************************************** */

  useEffect(() => {
    if (openModalEditUser) {
      onFill()
      resetPutUser()
      launchGetRoles()
    }
  }, [openModalEditUser])

  useEffect(() => {
    if (isSuccessPutUser) {
      setTimeout(() => {
        dispatch(
          updateUserAlert({
            title: 'Success',
            description: 'User updated successfully',
            status: 'success',
          }),
        )
      }, 150)
      dispatch(updateOpenModalEditUser(false))
    }
    if (isErrorPutUser) {
      setTimeout(() => {
        dispatch(
          updateUserAlert({
            title: 'Error',
            description:
              'Something went wrong! Please reload the page and try again.',
            status: 'error',
          }),
        )
      }, 150)
      dispatch(updateOpenModalEditUser(false))
    }
  }, [isSuccessPutUser, isErrorPutUser])

  useEffect(() => {
    if (dataGetRoles && dataGetRoles.data) {
      setListRoles(dataGetRoles.data)
    }
  }, [dataGetRoles])

  // ************************************************ */
  // COMPONENT ************************************** */
  return (
    <Drawer
      width="35%"
      placement="right"
      onClose={onClose}
      closable={false}
      visible={openModalEditUser}
    >
      <Spin spinning={isLoadingPutUser} tip={t('general.loading')}>
        <div>
          <h5>
            <FontAwesomeIcon
              style={{ marginRight: '10px' }}
              icon={faBuildingCircleExclamation}
              className="generalStyles__info"
            />
            {t('userManagement.editUser')}
          </h5>
          <Divider />
        </div>
        <Form form={form} layout="vertical" onFinish={onFinish}>
          <div className="container">
            <div className="row">
              <div className="col-12">
                {/** ---------------------------------------------------- */}
                {/** ROLE */}
                <Form.Item
                  name="role"
                  label={
                    <>
                      <span className="generalStyles__text">
                        {t('userManagement.role')}
                      </span>
                    </>
                  }
                  rules={[
                    {
                      required: true,
                      message: t('userManagement.formRequiredMessage.role'),
                    },
                  ]}
                >
                  <Select
                    disabled={listRoles.length === 0}
                    showSearch
                    optionFilterProp="children"
                    filterOption={(input: any, option: any) =>
                      option.children
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                  >
                    {listRoles.map((st) => (
                      <Option key={st.id} value={st.id}>
                        {st.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
                {/** ---------------------------------------------------- */}
                {/** ORGANIZATION */}
                <Form.Item
                  name="organization"
                  label={
                    <>
                      <span className="generalStyles__text">
                        {t('userManagement.organization')}
                      </span>
                    </>
                  }
                >
                  <Input disabled className="generalStyles__input" />
                </Form.Item>
              </div>
              <div className="col-12">
                {/** ---------------------------------------------------- */}
                {/** FIRTS NAME */}
                <Form.Item
                  name="firstName"
                  label={
                    <>
                      <span className="generalStyles__text">
                        {t('userManagement.firstName')}
                      </span>
                    </>
                  }
                  rules={[
                    {
                      required: true,
                      message: t(
                        'userManagement.formRequiredMessage.firstName',
                      ),
                    },
                  ]}
                >
                  <Input className="generalStyles__input" />
                </Form.Item>
              </div>
              <div className="col-12">
                {/** ---------------------------------------------------- */}
                {/** MIDDLE NAME */}
                <Form.Item
                  name="middleName"
                  label={
                    <>
                      <span className="generalStyles__text">
                        {t('userManagement.middleName')}
                      </span>
                    </>
                  }
                >
                  <Input className="generalStyles__input" />
                </Form.Item>
              </div>
              <div className="col-12">
                {/** ---------------------------------------------------- */}
                {/** LAST NAME */}
                <Form.Item
                  name="lastName"
                  label={
                    <>
                      <span className="generalStyles__text">
                        {t('userManagement.lastName')}
                      </span>
                    </>
                  }
                  rules={[
                    {
                      required: true,
                      message: t('userManagement.formRequiredMessage.lastName'),
                    },
                  ]}
                >
                  <Input className="generalStyles__input" />
                </Form.Item>
              </div>
              <div className="col-12">
                {/** ---------------------------------------------------- */}
                {/** EMAIL */}
                <Form.Item
                  name="email"
                  label={
                    <>
                      <span className="generalStyles__text">
                        {t('userManagement.email')}
                      </span>
                    </>
                  }
                  rules={[
                    {
                      required: true,
                      message: t('userManagement.formRequiredMessage.email'),
                    },
                  ]}
                >
                  <Input className="generalStyles__input" />
                </Form.Item>
              </div>
              <div className="col-12">
                {/** ---------------------------------------------------- */}
                {/** ADDRESS */}
                <Form.Item
                  name="address"
                  label={
                    <>
                      <span className="generalStyles__text">
                        {t('userManagement.address')}
                      </span>
                    </>
                  }
                  rules={[
                    {
                      required: true,
                      message: t('userManagement.formRequiredMessage.address'),
                    },
                  ]}
                >
                  <Input className="generalStyles__input" />
                </Form.Item>
              </div>
              <div className="col-12">
                {/** ---------------------------------------------------- */}
                {/** PHONE */}
                <Form.Item
                  name="phone"
                  label={
                    <>
                      <span className="generalStyles__text">
                        {t('organizationManagement.phoneNumber')}
                      </span>
                    </>
                  }
                  rules={[
                    {
                      required: true,
                      message: t(
                        'organizationManagement.formRequiredMessage.phone',
                      ),
                      type: 'number',
                    },
                  ]}
                >
                  <InputNumber
                    addonBefore={
                      <Form.Item
                        name="dialCode"
                        rules={[
                          {
                            required: true,
                            message: t(
                              'userManagement.formRequiredMessage.dialCode',
                            ),
                          },
                        ]}
                        style={{ backgroundColor: 'red' }}
                        noStyle
                      >
                        <InputNumber
                          prefix="+"
                          style={{
                            width: '100px',
                            marginLeft: '-12px',
                            marginRight: '-12px',
                            borderTop: 'none',
                            borderBottom: 'none',
                          }}
                        />
                      </Form.Item>
                    }
                    style={{ width: '100%' }}
                  />
                </Form.Item>
              </div>
            </div>
          </div>
          {/** ---------------------------------------------------- */}
          <div className="mt-4">
            <div>
              <Button
                htmlType="submit"
                disabled={isLoadingPutUser}
                style={{ marginRight: '10px' }}
                type="primary"
              >
                {t('userManagement.submit')}
              </Button>
              <Button onClick={handleCloseModal}>
                {t('userManagement.close')}
              </Button>
            </div>
          </div>
        </Form>
      </Spin>
    </Drawer>
  )
}

export default UsersEditUserForm
