/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface OrganizationState {
  organization: Object;
  openModalCreateNewOrganization: boolean;
  openModalEditOrganization: boolean;
  openModalDeleteOrganization: boolean;
  openDrawerOrganization: boolean;
  organizationAlert: Object;
  organizationTableParams: Object;
  organizationRefreshTable: boolean;
  organizationList: Object;
  organizationAndChilds: Object;
}

const initialState: OrganizationState = {
  organization: {},
  openModalCreateNewOrganization: false,
  openModalEditOrganization: false,
  openModalDeleteOrganization: false,
  openDrawerOrganization: false,
  organizationAlert: {},
  organizationTableParams: {
    pagination: {
      current: 1,
      pageSize: 10,
    },
  },
  organizationRefreshTable: false,
  organizationList: [],
  organizationAndChilds: {
    childs: [],
  },
};

export const organizationSlice = createSlice({
  name: 'organization',
  initialState,
  reducers: {
    updateOrganization: (state, action: PayloadAction<Object>) => {
      state.organization = action.payload;
    },
    updateOpenModalCreateNewOrganization: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.openModalCreateNewOrganization = action.payload;
    },
    updateOpenModalEditOrganization: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.openModalEditOrganization = action.payload;
    },
    updateOpenModalDeleteOrganization: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.openModalDeleteOrganization = action.payload;
    },
    updateOpenDrawerOrganization: (state, action: PayloadAction<boolean>) => {
      state.openDrawerOrganization = action.payload;
    },
    updateOrganizationAlert: (state, action: PayloadAction<Object>) => {
      state.organizationAlert = action.payload;
    },
    updateOrganizationTableParams: (state, action: PayloadAction<Object>) => {
      state.organizationTableParams = action.payload;
    },
    updateOrganizationRefreshTable: (state, action: PayloadAction<boolean>) => {
      state.organizationRefreshTable = action.payload;
    },
    updateOrganizationList: (state, action: PayloadAction<any>) => {
      state.organizationList = action.payload;
    },
    updateOrganizationAndChilds: (state, action: PayloadAction<Object>) => {
      state.organizationAndChilds = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  updateOrganization,
  updateOpenModalCreateNewOrganization,
  updateOpenModalEditOrganization,
  updateOpenModalDeleteOrganization,
  updateOpenDrawerOrganization,
  updateOrganizationAlert,
  updateOrganizationTableParams,
  updateOrganizationRefreshTable,
  updateOrganizationList,
  updateOrganizationAndChilds,
} = organizationSlice.actions;

export default organizationSlice.reducer;
