import React from 'react'
// ANT DESIGN COMPONENT
import { Divider } from 'antd'
// I18N TRANSLATION
import { useTranslation } from 'react-i18next'
// SWITCH THEME
import { useThemeSwitcher } from 'react-css-theme-switcher'
// FONT AWESOME LIBRYARY AND ICONS
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCircleCheck,
  faSun,
  faMoon,
  faPaintBrush,
} from '@fortawesome/free-solid-svg-icons'
// REDUX
import { useSelector, useDispatch } from 'react-redux'
import { updateTheme } from '../../redux/home/homeSlice'
// IMAGES
import lightMode from '../../assets/lightMode.png'
import darkMode from '../../assets/darkMode.png'

library.add(faCircleCheck, faSun, faMoon, faPaintBrush)

function Appearance() {
  // ************************************************ */
  // LOCAL STORAGE AND VARIABLES ******************** */
  const dispatch = useDispatch()
  const { switcher, themes } = useThemeSwitcher()
  const [t] = useTranslation('global')
  const { theme } = useSelector((state: any) => state.home)

  // ************************************************* */
  // FUNCTIONS ************************************** */

  const handleChangeTheme = (newTheme: any) => {
    switcher({ theme: newTheme === 'dark' ? themes.dark : themes.light })
    localStorage.setItem('theme', newTheme)
    dispatch(updateTheme(newTheme))
  }

  // ************************************************* */
  // COMPONENT ************************************** */
  return (
    <div>
      {/** ************************************************* */}
      <div>
        <h5>
          <p className="generalStyles__text">
            <FontAwesomeIcon
              icon={faPaintBrush}
              className="generalStyles__info"
              style={{ marginRight: '10px' }}
            />
            {t('appearance.appearance')}
          </p>
        </h5>
        <Divider />
      </div>
      {/** */}
      <div className="generalStyles__flex">
        {/** DARK MODE BOX */}
        <div
          className={
            theme === 'dark'
              ? 'settings__activeCard settings__activeBox__dark'
              : 'settings__card settings__activeBox__light'
          }
          role="button"
          tabIndex={0}
          onClick={() => {
            handleChangeTheme('dark')
          }}
          onKeyDown={() => {
            handleChangeTheme('dark')
          }}
        >
          <div>
            <div className="settings__borderBottom generalStyles__flex settings__cardHeader">
              <div>
                <p className="generalStyles__text settings__themeTitle m-3">
                  <FontAwesomeIcon
                    icon={faMoon}
                    className="settings__moonIcon"
                  />
                  {t('appearance.nightTheme')}
                </p>
              </div>

              {theme === 'dark' ? (
                <FontAwesomeIcon
                  icon={faCircleCheck}
                  className="settings__activeBox"
                />
              ) : (
                <></>
              )}
            </div>
            <div>
              <img className="settings__cardImage" src={darkMode} alt="" />
            </div>
          </div>
        </div>
        {/** LIGHT MODE BOX */}
        <div
          className={
            theme === 'light'
              ? 'settings__activeCard settings__activeBox__light'
              : 'settings__card settings__activeBox__dark'
          }
          role="button"
          tabIndex={0}
          onClick={() => {
            handleChangeTheme('light')
          }}
          onKeyDown={() => {
            handleChangeTheme('light')
          }}
        >
          <div>
            <div className="settings__borderBottom generalStyles__flex settings__cardHeader">
              <div>
                <p className="generalStyles__text settings__themeTitle m-3">
                  <FontAwesomeIcon icon={faSun} className="settings__sunIcon" />
                  {t('appearance.dayTheme')}
                </p>
              </div>
              {theme === 'light' ? (
                <FontAwesomeIcon
                  icon={faCircleCheck}
                  className="settings__activeBox"
                />
              ) : (
                <></>
              )}
            </div>
            <div>
              <img className="settings__cardImage" src={lightMode} alt="" />
            </div>
          </div>
        </div>
      </div>
      <br />
      <br />
    </div>
  )
}

export default Appearance
