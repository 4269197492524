import React, { useEffect, useState } from 'react'
// ANT DESIGN COMPONENTS
import {
  Button,
  Form,
  Input,
  Select,
  InputNumber,
  Spin,
  Divider,
  Drawer,
} from 'antd'
// FONT AWESOME LIBRYARY AND ICONS
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBuildingCircleCheck } from '@fortawesome/free-solid-svg-icons'
// I18N TRANSLATION
import { useTranslation } from 'react-i18next'
// REDUX
import { useDispatch, useSelector } from 'react-redux'
import {
  usePostOrganizationMutation,
  useLazyGetOrganizationAndChildsQuery,
} from '../../redux/organizations/organizationAPI'
import {
  updateOrganizationAlert,
  updateOpenModalCreateNewOrganization,
  updateOrganizationAndChilds,
} from '../../redux/organizations/organizationSlice'
// AUTHORIZATION
import GETJwtToken from '../../redux/authentication'
import {
  useGetCountriesMutation,
  useGetStatesMutation,
  useGetCitiesMutation,
} from '../../redux/countriesStatesCitiesAPI'

const { Option } = Select
library.add(faBuildingCircleCheck)

function OrganizationsCreateOrganizationForm() {
  // ************************************************ */
  // LOCAL STORAGE AND VARIABLES ******************** */
  const dispatch = useDispatch()
  const { openModalCreateNewOrganization, organizationAndChilds } = useSelector(
    (state: any) => state.organization,
  )
  //
  const [form] = Form.useForm()
  const [country, setCountry] = useState('')
  const [listCountries, setListCountries] = useState<any[]>([])
  const [listStates, setListStates] = useState<any[]>([])
  const [listCities, setListCities] = useState<any[]>([])
  const [listOrganizations, setListOrganizations] = useState<any[]>([])
  const [idOrganizationSelected, setIdOrganizationSelected] = useState('')
  const [
    isLoadingGetOrganizationAndChilds,
    setIsLoadingGetOrganizationAndChilds,
  ] = useState(false)
  const masterID = '4f594f28-9896-4ffb-9e40-0693c06bde69'
  const [t] = useTranslation('global')
  const [getCountries, { data: countries }] = useGetCountriesMutation()
  const [
    getStates,
    { data: states, isLoading: isLoadingStates, reset: resetGetStates },
  ] = useGetStatesMutation()
  const [
    getCities,
    { data: cities, isLoading: isLoadingCities, reset: resetGetCities },
  ] = useGetCitiesMutation()
  //
  const [
    postOrganization,
    {
      isSuccess: isSuccessPostOrganization,
      isLoading: isLoadingPostOrganization,
      isError: isErrorPostOrganization,
      reset: resetPostOrganization,
    },
  ] = usePostOrganizationMutation()
  const [
    triggerGetOrganizationAndChilds,
    { isError: isErrorGetOrganizationAndChilds },
  ] = useLazyGetOrganizationAndChildsQuery()

  // ************************************************ */
  // FUNCTIONS ************************************** */
  const cleanOrganizationsList = () => {
    dispatch(
      updateOrganizationAndChilds({
        childs: [],
      }),
    )
  }

  const onClose = () => {
    cleanOrganizationsList()
    dispatch(updateOpenModalCreateNewOrganization(false))
  }

  const onResetStateCity = () => {
    form.setFieldsValue({
      state: '',
      city: '',
    })
    setListCities([])
    resetGetCities()
  }

  const handleCloseModal = () => {
    cleanOrganizationsList()
    dispatch(updateOpenModalCreateNewOrganization(false))
  }

  const handleChangeCountry = (value: string) => {
    onResetStateCity()
    setCountry(value)
    getStates(value)
  }

  const handleChangeState = (value: string) => {
    getCities({ country, state: value })
  }

  const onFinish = async (values: any) => {
    const token = await GETJwtToken()
    const BODY = {
      body: {
        Name: values.name,
        Address: values.address,
        phone: values.phone.toString(),
        phone_code: values.dialCode.toString(),
        Type: idOrganizationSelected !== '' ? 'SUBORG' : 'ORG',
        Country: values.country,
        parent_id:
          idOrganizationSelected !== '' ? idOrganizationSelected : masterID,
        State: values.state,
        City: values.city,
      },
      token,
    }
    postOrganization(BODY)
  }

  const getOrganizationAndChilds = async (orgId: string) => {
    const token = await GETJwtToken()
    setIsLoadingGetOrganizationAndChilds(true)
    const data = await triggerGetOrganizationAndChilds({
      orgId,
      token,
    }).unwrap()
    setIsLoadingGetOrganizationAndChilds(false)
    if (
      data &&
      data.data &&
      data.data.children &&
      data.data.children.length > 0
    ) {
      const BODY = {
        childs: [
          ...organizationAndChilds.childs,
          {
            level: organizationAndChilds.childs.length + 1,
            data: data.data.children,
            orgSelected: orgId,
          },
        ],
      }
      dispatch(updateOrganizationAndChilds(BODY))
    }
  }

  const handleChangeOrganization = (value: string, level: number) => {
    const totalChilds = organizationAndChilds.childs.length
    // if we select a new value
    if (value) {
      setIdOrganizationSelected(value)
      getOrganizationAndChilds(value)
    }
    // if we select the last value of the list
    else if (totalChilds === level) {
      setIdOrganizationSelected(
        organizationAndChilds.childs[level - 1].orgSelected,
      )
      form.setFieldsValue({ [`assignToOrganization${level}`]: undefined })
    }
    // if we select a middle value
    else {
      const newArray = []
      setIdOrganizationSelected(
        organizationAndChilds.childs[level - 1].orgSelected,
      )
      for (let index = 0; index < level; index += 1) {
        newArray.push(organizationAndChilds.childs[index])
      }
      for (let index = level + 1; index <= totalChilds; index += 1) {
        form.setFieldsValue({ [`assignToOrganization${index}`]: undefined })
      }
      const BODY = {
        childs: newArray,
      }
      dispatch(updateOrganizationAndChilds(BODY))
    }
  }

  // ************************************************* */
  // USE EFFECT ************************************** */
  useEffect(() => {
    if (openModalCreateNewOrganization) {
      resetPostOrganization()
      resetGetStates()
      resetGetCities()
      setListStates([])
      setListCities([])
      form.resetFields()
      if (openModalCreateNewOrganization) {
        getCountries({})
      }
      getOrganizationAndChilds(masterID)
    }
  }, [openModalCreateNewOrganization])

  useEffect(() => {
    if (countries && countries.data && countries.data.length > 0) {
      setListCountries(countries.data)
    }
  }, [countries])

  useEffect(() => {
    if (
      states &&
      states.data &&
      states.data.states &&
      states.data.states.length > 0
    ) {
      setListStates(states.data.states)
    }
  }, [states])

  useEffect(() => {
    if (cities && cities.data && cities.data.length > 0) {
      setListCities(cities.data)
    }
  }, [cities])

  useEffect(() => {
    if (isSuccessPostOrganization) {
      setTimeout(() => {
        dispatch(
          updateOrganizationAlert({
            title: 'Success',
            description: 'Organization created successfully',
            status: 'success',
          }),
        )
      }, 150)
      cleanOrganizationsList()
      dispatch(updateOpenModalCreateNewOrganization(false))
    }
    if (isErrorPostOrganization) {
      setTimeout(() => {
        dispatch(
          updateOrganizationAlert({
            title: 'Error',
            description:
              'Something went wrong! Please reload the page and try again.',
            status: 'error',
          }),
        )
      }, 150)
      cleanOrganizationsList()
      dispatch(updateOpenModalCreateNewOrganization(false))
    }
  }, [isSuccessPostOrganization, isErrorPostOrganization])

  useEffect(() => {
    if (isErrorGetOrganizationAndChilds) {
      setTimeout(() => {
        dispatch(
          updateOrganizationAlert({
            title: 'Error',
            description:
              'Something went wrong! Please reload the page and try again.',
            status: 'error',
          }),
        )
      }, 150)
      cleanOrganizationsList()
      dispatch(updateOpenModalCreateNewOrganization(false))
    }
  }, [isErrorGetOrganizationAndChilds])

  useEffect(() => {
    if (organizationAndChilds && organizationAndChilds.childs) {
      setListOrganizations(organizationAndChilds.childs)
    }
  }, [organizationAndChilds])

  // ************************************************ */
  // COMPONENT ************************************** */
  return (
    <Drawer
      width="35%"
      placement="right"
      onClose={onClose}
      closable={false}
      visible={openModalCreateNewOrganization}
    >
      <Spin
        spinning={
          isLoadingPostOrganization ||
          isLoadingCities ||
          isLoadingStates ||
          isLoadingGetOrganizationAndChilds
        }
        tip={t('general.loading')}
      >
        <div>
          <h5>
            <FontAwesomeIcon
              style={{ marginRight: '10px' }}
              icon={faBuildingCircleCheck}
              className="generalStyles__info"
            />
            <span className="generalStyles__text">
              {t('organizationManagement.createNewOrganization')}
            </span>
          </h5>
          <Divider />
        </div>
        <Form form={form} layout="vertical" onFinish={onFinish}>
          <div className="container">
            <div className="row">
              <div className="col-12">
                {/** ---------------------------------------------------- */}
                {/** NAME */}
                <Form.Item
                  name="name"
                  label={
                    <>
                      <span className="generalStyles__text">
                        {t('organizationManagement.organizationName')}
                      </span>
                    </>
                  }
                  rules={[
                    {
                      required: true,
                      message: t(
                        'organizationManagement.formRequiredMessage.name',
                      ),
                    },
                  ]}
                >
                  <Input className="generalStyles__input" />
                </Form.Item>
              </div>
              <div className="col-12">
                {/** ---------------------------------------------------- */}
                {/** ASSIGN TO ORGANIZATION */}
                {listOrganizations.map((organization: any) => (
                  <Form.Item
                    key={organization.level}
                    name={`assignToOrganization${organization.level}`}
                    label={
                      <>
                        <span className="generalStyles__text">
                          {organization.level === 1 ? (
                            <>{t('userManagement.assignToOrganization')}</>
                          ) : (
                            <>{`${t('userManagement.subOrganization')}`}</>
                          )}
                        </span>
                      </>
                    }
                    rules={[
                      {
                        required: organization.level === 0,
                        message:
                          organization.level === 0
                            ? t(
                                'userManagement.formRequiredMessage.organization',
                              )
                            : t(
                                'userManagement.formRequiredMessage.subOrganization',
                              ),
                      },
                    ]}
                  >
                    <Select
                      showSearch
                      optionFilterProp="children"
                      onChange={(value) => {
                        handleChangeOrganization(value, organization.level)
                      }}
                      allowClear
                      filterOption={(input: any, option: any) =>
                        option.children
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                    >
                      {organization.data.map((item: any) => (
                        <Option key={item.id} value={item.id}>
                          {item.name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                ))}
              </div>
              <div className="col-12">
                {/** ---------------------------------------------------- */}
                {/** ADDRESS */}
                <Form.Item
                  name="address"
                  label={
                    <>
                      <span className="generalStyles__text">
                        {t('organizationManagement.address')}
                      </span>
                    </>
                  }
                  rules={[
                    {
                      required: true,
                      message: t(
                        'organizationManagement.formRequiredMessage.address',
                      ),
                    },
                  ]}
                >
                  <Input className="generalStyles__input" />
                </Form.Item>
              </div>
            </div>
            <div className="row generalStyles__inputContainerTopFix">
              <div className="col-12">
                {/** ---------------------------------------------------- */}
                {/** PHONE */}
                <Form.Item
                  name="phone"
                  label={
                    <>
                      <span className="generalStyles__text">
                        {t('organizationManagement.phoneNumber')}
                      </span>
                    </>
                  }
                  rules={[
                    {
                      required: true,
                      message: t(
                        'organizationManagement.formRequiredMessage.phone',
                      ),
                      type: 'number',
                    },
                  ]}
                >
                  <InputNumber
                    addonBefore={
                      <Form.Item
                        name="dialCode"
                        rules={[
                          {
                            required: true,
                            message: t(
                              'userManagement.formRequiredMessage.dialCode',
                            ),
                          },
                        ]}
                        noStyle
                      >
                        <InputNumber
                          prefix="+"
                          style={{
                            width: '100px',
                            marginLeft: '-12px',
                            marginRight: '-12px',
                            borderTop: 'none',
                            borderBottom: 'none',
                          }}
                        />
                      </Form.Item>
                    }
                    style={{ width: '100%' }}
                  />
                </Form.Item>
              </div>
              <div className="col-12">
                {/** ---------------------------------------------------- */}
                {/** COUNTRY */}
                <Form.Item
                  name="country"
                  label={
                    <>
                      <span className="generalStyles__text">
                        {t('organizationManagement.country')}
                      </span>
                    </>
                  }
                  rules={[
                    {
                      required: true,
                      message: t(
                        'organizationManagement.formRequiredMessage.country',
                      ),
                    },
                  ]}
                >
                  <Select
                    showSearch
                    optionFilterProp="children"
                    onChange={handleChangeCountry}
                    filterOption={(input: any, option: any) =>
                      option.children
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                  >
                    {listCountries.map((c) => (
                      <Option key={c.name} value={c.name}>
                        {c.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </div>
            </div>
            <div className="row generalStyles__inputContainerTopFix">
              <div className="col-12">
                {/** ---------------------------------------------------- */}
                {/** STATE */}
                <Form.Item
                  name="state"
                  label={
                    <>
                      <span className="generalStyles__text">
                        {t('organizationManagement.state')}
                      </span>
                    </>
                  }
                  rules={[
                    {
                      required: true,
                      message: t(
                        'organizationManagement.formRequiredMessage.state',
                      ),
                    },
                  ]}
                >
                  <Select
                    disabled={listStates.length === 0}
                    showSearch
                    optionFilterProp="children"
                    onChange={handleChangeState}
                    filterOption={(input: any, option: any) =>
                      option.children
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                  >
                    {listStates.map((st) => (
                      <Option key={st.name} value={st.name}>
                        {st.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </div>
              <div className="col-12">
                {/** ---------------------------------------------------- */}
                {/** CITY */}
                <Form.Item
                  name="city"
                  label={
                    <>
                      <span className="generalStyles__text">
                        {t('organizationManagement.city')}
                      </span>
                    </>
                  }
                >
                  <Select
                    disabled={listCities.length === 0}
                    showSearch
                    optionFilterProp="children"
                    filterOption={(input: any, option: any) =>
                      option.children
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                  >
                    {listCities.map((c) => (
                      <Option key={c} value={c}>
                        {c}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </div>
            </div>
          </div>
          {/** ---------------------------------------------------- */}
          <div className="mt-4">
            <div>
              <Button
                htmlType="submit"
                disabled={isLoadingPostOrganization}
                style={{ marginRight: '10px' }}
                type="primary"
              >
                {t('organizationManagement.submit')}
              </Button>
              <Button type="default" onClick={handleCloseModal}>
                {t('organizationManagement.close')}
              </Button>
            </div>
          </div>
        </Form>
      </Spin>
    </Drawer>
  )
}

export default OrganizationsCreateOrganizationForm
