import React, { useEffect, useState } from 'react'
// ANT DESIGN COMPONENTS
import { Divider, Drawer, Tag } from 'antd'
// I18N TRANSLATION
import { useTranslation } from 'react-i18next'
// FONT AWESOME LIBRYARY AND ICONS
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faUser,
  faBuilding,
  faBuildingLock,
  faCheckCircle,
  faCircleExclamation,
  faBan,
  faUserPlus,
} from '@fortawesome/free-solid-svg-icons'
// REDUX
import { useDispatch, useSelector } from 'react-redux'
import { updateOpenDrawerUser } from '../../redux/users/userSlice'

library.add(
  faUser,
  faBuilding,
  faBuildingLock,
  faCheckCircle,
  faCircleExclamation,
  faBan,
  faUserPlus,
)

function UserDrawer() {
  // ************************************************ */
  // LOCAL STORAGE AND VARIABLES ******************** */
  const dispatch = useDispatch()
  const { user, openDrawerUser } = useSelector((state: any) => state.user)
  const [data, setData] = useState<any>({})
  const [t] = useTranslation('global')
  // ************************************************ */
  // FUNCTIONS ************************************** */
  const onClose = () => {
    dispatch(updateOpenDrawerUser(false))
  }

  // ************************************************* */
  // USE EFFECT ************************************** */
  useEffect(() => {
    if (user) {
      setData(user)
    }
  }, [user])

  // ************************************************ */
  // COMPONENT ************************************** */
  return (
    <Drawer
      width="35%"
      placement="right"
      onClose={onClose}
      closable={false}
      visible={openDrawerUser}
    >
      {data && (
        <div>
          {/** ************************************************* */}
          <div>
            <h5>
              <p className="generalStyles__text">
                <FontAwesomeIcon
                  style={{ marginRight: '10px' }}
                  icon={faUser}
                  className="generalStyles__info"
                />
                {t('userManagement.userInformation')}
              </p>
            </h5>
            <Divider />
          </div>
          {/* -*-*-*-*-*-*-*-*-*-*-*-*-*-*- */}
          <div className="generalStyles__flex">
            <div className="yourProfile__drawerContainerText1">
              <p className="drawer__title">User ID:</p>
            </div>
            <div className="yourProfile__drawerContainerText2">
              <p className="generalStyles__text">{data.id ? data.id : '--'}</p>
            </div>
          </div>
          {/* -*-*-*-*-*-*-*-*-*-*-*-*-*-*- */}
          <div className="generalStyles__flex">
            <div className="yourProfile__drawerContainerText1">
              <p className="drawer__title">{t('userManagement.firstName')}</p>
            </div>
            <div className="yourProfile__drawerContainerText2">
              <p className="generalStyles__text">
                {data.first_name ? data.first_name : '--'}
              </p>
            </div>
          </div>
          {/* -*-*-*-*-*-*-*-*-*-*-*-*-*-*- */}
          <div className="generalStyles__flex">
            <div className="yourProfile__drawerContainerText1">
              <p className="drawer__title">{t('userManagement.middleName')}</p>
            </div>
            <div className="yourProfile__drawerContainerText2">
              <p className="generalStyles__text">
                {data.middle_name ? data.middle_name : '--'}
              </p>
            </div>
          </div>
          {/* -*-*-*-*-*-*-*-*-*-*-*-*-*-*- */}
          <div className="generalStyles__flex">
            <div className="yourProfile__drawerContainerText1">
              <p className="drawer__title">{t('userManagement.lastName')}</p>
            </div>
            <div className="yourProfile__drawerContainerText2">
              <p className="generalStyles__text">
                {data.last_name ? data.last_name : '--'}
              </p>
            </div>
          </div>
          {/* -*-*-*-*-*-*-*-*-*-*-*-*-*-*- */}
          <div className="generalStyles__flex">
            <div className="yourProfile__drawerContainerText1">
              <p className="drawer__title">{t('userManagement.email')}</p>
            </div>
            <div className="yourProfile__drawerContainerText2">
              <p className="generalStyles__text">
                {data.email ? data.email : '--'}
              </p>
            </div>
          </div>

          {/* -*-*-*-*-*-*-*-*-*-*-*-*-*-*- */}
          <div className="generalStyles__flex">
            <div className="yourProfile__drawerContainerText1">
              <p className="drawer__title">{t('userManagement.address')}</p>
            </div>
            <div className="yourProfile__drawerContainerText2">
              <p className="generalStyles__text">
                {data.contact_info &&
                data.contact_info[0] &&
                data.contact_info[0].address
                  ? data.contact_info[0].address
                  : '--'}
              </p>
            </div>
          </div>

          {/* -*-*-*-*-*-*-*-*-*-*-*-*-*-*- */}
          <div className="generalStyles__flex">
            <div className="yourProfile__drawerContainerText1">
              <p className="drawer__title">{t('userManagement.phone')}</p>
            </div>
            <div className="yourProfile__drawerContainerText2">
              <p className="generalStyles__text">
                {data.contact_info &&
                data.contact_info[0] &&
                data.contact_info[0].phone
                  ? data.contact_info[0].phone
                  : '--'}
              </p>
            </div>
          </div>

          {/* -*-*-*-*-*-*-*-*-*-*-*-*-*-*- */}
          <div className="generalStyles__flex">
            <div className="yourProfile__drawerContainerText1">
              <p className="drawer__title">{t('userManagement.dialCode')}</p>
            </div>
            <div className="yourProfile__drawerContainerText2">
              <p className="generalStyles__text">
                {data.contact_info &&
                data.contact_info[0] &&
                data.contact_info[0].phone_code
                  ? data.contact_info[0].phone_code
                  : '--'}
              </p>
            </div>
          </div>
          {/* -*-*-*-*-*-*-*-*-*-*-*-*-*-*- */}
          <div className="generalStyles__flex">
            <div className="yourProfile__drawerContainerText1">
              <p className="drawer__title">{t('userManagement.status')}</p>
            </div>
            <div className="yourProfile__drawerContainerText2">
              <p className="generalStyles__text">
                {data.status ? (
                  <>
                    {data.status === 'ACTIVE' && (
                      <Tag
                        color="success"
                        icon={
                          <FontAwesomeIcon
                            icon={faCheckCircle}
                            style={{ marginRight: '5px' }}
                          />
                        }
                        style={{ width: '90px' }}
                      >
                        Active
                      </Tag>
                    )}
                    {data.status === 'NEW' && (
                      <Tag
                        color="warning"
                        icon={
                          <FontAwesomeIcon
                            icon={faCircleExclamation}
                            style={{ marginRight: '5px' }}
                          />
                        }
                        style={{ width: '90px' }}
                      >
                        New user
                      </Tag>
                    )}
                    {data.status === 'INACTIVE' && (
                      <Tag
                        color="error"
                        icon={
                          <FontAwesomeIcon
                            icon={faBan}
                            style={{ marginRight: '5px' }}
                          />
                        }
                        style={{ width: '90px' }}
                      >
                        Inactive
                      </Tag>
                    )}
                  </>
                ) : (
                  '--'
                )}
              </p>
            </div>
          </div>
          <br />
          <br />
          {/** ************************************************* */}
          {/** ************************************************* */}
          <div>
            <h5>
              <p className="generalStyles__text">
                <FontAwesomeIcon
                  style={{ marginRight: '10px' }}
                  icon={faUserPlus}
                  className="generalStyles__info"
                />
                {t('userManagement.roleInformation')}
              </p>
            </h5>
            <Divider />
          </div>
          {/* -*-*-*-*-*-*-*-*-*-*-*-*-*-*- */}
          <div className="generalStyles__flex">
            <div className="yourProfile__drawerContainerText1">
              <p className="drawer__title">{t('userManagement.roleId')}</p>
            </div>
            <div className="yourProfile__drawerContainerText2">
              <p className="generalStyles__text">
                {data.role && data.role.id ? data.role.id : '--'}
              </p>
            </div>
          </div>
          {/* -*-*-*-*-*-*-*-*-*-*-*-*-*-*- */}
          <div className="generalStyles__flex">
            <div className="yourProfile__drawerContainerText1">
              <p className="drawer__title"> {t('userManagement.name')}</p>
            </div>
            <div className="yourProfile__drawerContainerText2">
              <p className="generalStyles__text">
                {data.role && data.role.name ? data.role.name : '--'}
              </p>
            </div>
          </div>
          {/* -*-*-*-*-*-*-*-*-*-*-*-*-*-*- */}
          <div className="generalStyles__flex">
            <div className="yourProfile__drawerContainerText1">
              <p className="drawer__title"> {t('userManagement.status')}</p>
            </div>
            <div className="yourProfile__drawerContainerText2">
              <p className="generalStyles__text">
                {data.role && data.role.active ? (
                  <Tag
                    color="success"
                    icon={
                      <FontAwesomeIcon
                        icon={faCheckCircle}
                        style={{ marginRight: '5px' }}
                      />
                    }
                    style={{ width: '90px' }}
                  >
                    Active
                  </Tag>
                ) : (
                  <Tag
                    color="error"
                    icon={
                      <FontAwesomeIcon
                        icon={faCheckCircle}
                        style={{ marginRight: '5px' }}
                      />
                    }
                    style={{ width: '90px' }}
                  >
                    Locked
                  </Tag>
                )}{' '}
              </p>
            </div>
          </div>
          <br />
          <br />
          {/** ************************************************* */}
          {/** ************************************************* */}
          <div>
            <h5>
              <p className="generalStyles__text">
                <FontAwesomeIcon
                  style={{ marginRight: '10px' }}
                  icon={faBuilding}
                  className="generalStyles__info"
                />
                {t('organizationManagement.organizationInformation')}
              </p>
            </h5>
            <Divider />
          </div>
          {/* -*-*-*-*-*-*-*-*-*-*-*-*-*-*- */}
          <div className="generalStyles__flex">
            <div className="yourProfile__drawerContainerText1">
              <p className="drawer__title">
                {t('organizationManagement.organizationId')}
              </p>
            </div>
            <div className="yourProfile__drawerContainerText2">
              <p className="generalStyles__text">
                {data.org && data.org.id ? data.org.id : '--'}
              </p>
            </div>
          </div>
          {/* -*-*-*-*-*-*-*-*-*-*-*-*-*-*- */}
          <div className="generalStyles__flex">
            <div className="yourProfile__drawerContainerText1">
              <p className="drawer__title">
                {t('organizationManagement.name')}
              </p>
            </div>
            <div className="yourProfile__drawerContainerText2">
              <p className="generalStyles__text">
                {data.org && data.org.name ? data.org.name : '--'}
              </p>
            </div>
          </div>
          {/* -*-*-*-*-*-*-*-*-*-*-*-*-*-*- */}
          <div className="generalStyles__flex">
            <div className="yourProfile__drawerContainerText1">
              <p className="drawer__title">
                {t('organizationManagement.type')}
              </p>
            </div>
            <div className="yourProfile__drawerContainerText2">
              <p className="generalStyles__text">
                {data.org && data.org.type ? (
                  <>
                    {data.org.type === 'ORG'
                      ? 'ORGANIZATION'
                      : 'SUB ORGANIZATION'}
                  </>
                ) : (
                  '--'
                )}
              </p>
            </div>
          </div>
          {/* -*-*-*-*-*-*-*-*-*-*-*-*-*-*- */}
          <div className="generalStyles__flex">
            <div className="yourProfile__drawerContainerText1">
              <p className="drawer__title">
                {t('organizationManagement.address')}
              </p>
            </div>
            <div className="yourProfile__drawerContainerText2">
              <p className="generalStyles__text">
                {data.org && data.org.address ? data.org.address : '--'}
              </p>
            </div>
          </div>
          {/* -*-*-*-*-*-*-*-*-*-*-*-*-*-*- */}
          <div className="generalStyles__flex">
            <div className="yourProfile__drawerContainerText1">
              <p className="drawer__title">
                {t('organizationManagement.country')}
              </p>
            </div>
            <div className="yourProfile__drawerContainerText2">
              <p className="generalStyles__text">
                {data.org && data.org.country ? data.org.country : '--'}
              </p>
            </div>
          </div>
          {/* -*-*-*-*-*-*-*-*-*-*-*-*-*-*- */}
          <div className="generalStyles__flex">
            <div className="yourProfile__drawerContainerText1">
              <p className="drawer__title">
                {t('organizationManagement.state')}
              </p>
            </div>
            <div className="yourProfile__drawerContainerText2">
              <p className="generalStyles__text">
                {data.org && data.org.state ? data.org.state : '--'}
              </p>
            </div>
          </div>
          {/* -*-*-*-*-*-*-*-*-*-*-*-*-*-*- */}
          <div className="generalStyles__flex">
            <div className="yourProfile__drawerContainerText1">
              <p className="drawer__title">
                {t('organizationManagement.city')}
              </p>
            </div>
            <div className="yourProfile__drawerContainerText2">
              <p className="generalStyles__text">
                {data.org && data.org.city ? data.org.city : '--'}
              </p>
            </div>
          </div>
          {/* -*-*-*-*-*-*-*-*-*-*-*-*-*-*- */}
          <div className="generalStyles__flex">
            <div className="yourProfile__drawerContainerText1">
              <p className="drawer__title">
                {t('organizationManagement.phoneNumber')}
              </p>
            </div>
            <div className="yourProfile__drawerContainerText2">
              <p className="generalStyles__text">
                {data.org && data.org.phone ? data.org.phone : '--'}
              </p>
            </div>
          </div>
          {/* -*-*-*-*-*-*-*-*-*-*-*-*-*-*- */}
          <div className="generalStyles__flex">
            <div className="yourProfile__drawerContainerText1">
              <p className="drawer__title">
                {t('organizationManagement.dialCode')}
              </p>
            </div>
            <div className="yourProfile__drawerContainerText2">
              <p className="generalStyles__text">
                {data.org && data.org.phone_code ? data.org.phone_code : '--'}
              </p>
            </div>
          </div>
        </div>
      )}
    </Drawer>
  )
}

export default UserDrawer
