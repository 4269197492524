/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface HomeState {
  userInformation: Object;
  breadcrumb: Object;
  theme: string;
  language: string;
  breadcrumbTitle: string;
}

const initialState: HomeState = {
  userInformation: {},
  breadcrumb: {},
  theme: 'light',
  language: 'en',
  breadcrumbTitle: '',
};

export const homeSlice = createSlice({
  name: 'home',
  initialState,
  reducers: {
    updateUserInformation: (state, action: PayloadAction<Object>) => {
      state.userInformation = action.payload;
    },
    updateBreadcrumb: (state, action: PayloadAction<Object>) => {
      state.breadcrumb = action.payload;
    },
    updateTheme: (state, action: PayloadAction<string>) => {
      state.theme = action.payload;
    },
    updateLanguage: (state, action: PayloadAction<string>) => {
      state.language = action.payload;
    },
    updateBreadcrumbTitle: (state, action: PayloadAction<string>) => {
      state.breadcrumbTitle = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  updateUserInformation,
  updateBreadcrumb,
  updateTheme,
  updateLanguage,
  updateBreadcrumbTitle,
} = homeSlice.actions;

export default homeSlice.reducer;
