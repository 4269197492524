/* eslint-disable no-use-before-define */
import React, { useEffect, useState } from 'react'
// ANT DESIGN COMPONENTS
import { Table, Alert, Menu, Dropdown, Space } from 'antd'
// I18N TRANSLATION
import { useTranslation } from 'react-i18next'
// FONT AWESOME LIBRYARY AND ICONS
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faEllipsis,
  faTrash,
  faPenToSquare,
} from '@fortawesome/free-solid-svg-icons'
// REDUX
import { useDispatch, useSelector } from 'react-redux'
import { useLazyGetOrganizationsQuery } from '../../redux/organizations/organizationAPI'
import {
  updateOpenModalDeleteOrganization,
  updateOpenModalEditOrganization,
  updateOpenDrawerOrganization,
  updateOrganization,
  updateOrganizationTableParams,
} from '../../redux/organizations/organizationSlice'
// AUTHORIZATION
import GETJwtToken from '../../redux/authentication'

library.add(faEllipsis, faTrash, faPenToSquare)

const OrganizationTable = () => {
  // ************************************************ */
  // LOCAL STORAGE AND VARIABLES ******************** */
  const dispatch = useDispatch()
  const [organizations, setOrganizations] = useState<any[]>([])
  const [selectedItem, setSelectedItem] = useState<any>({})
  const [t] = useTranslation('global')
  const [
    triggerGetOrganizationsTable,
    { data, isLoading, isError, isFetching },
  ] = useLazyGetOrganizationsQuery()
  const { organizationTableParams, organizationRefreshTable } = useSelector(
    (state: any) => state.organization,
  )

  const { Column } = Table

  const menu = (
    <Menu
      items={[
        {
          label: (
            <>
              {selectedItem.type !== 'MASTER' ? (
                <div
                  className="generalStyles__tableActionBox"
                  role="button"
                  tabIndex={0}
                  onClick={() => {
                    handleOpenModalEdit()
                  }}
                  onKeyDown={() => {
                    handleOpenModalEdit()
                  }}
                >
                  <span>
                    <FontAwesomeIcon
                      className="generalStyles__info"
                      icon={faPenToSquare}
                      style={{ marginRight: '10px' }}
                    />
                    {t('organizationManagement.edit')}
                  </span>
                </div>
              ) : (
                <></>
              )}
            </>
          ),
          key: '0',
        },
        {
          label: (
            <>
              {selectedItem.type !== 'MASTER' ? (
                <div
                  className="generalStyles__tableActionBox"
                  role="button"
                  tabIndex={0}
                  onClick={() => {
                    handleOpenModalDelete()
                  }}
                  onKeyDown={() => {
                    handleOpenModalDelete()
                  }}
                >
                  <span>
                    <FontAwesomeIcon
                      className="generalStyles__info"
                      icon={faTrash}
                      style={{ marginRight: '10px' }}
                    />
                    {t('organizationManagement.delete')}
                  </span>
                </div>
              ) : (
                <></>
              )}
            </>
          ),
          key: '1',
        },
      ]}
    />
  )

  // ************************************************ */
  // FUNCTIONS ************************************** */
  const handleOpenModalEdit = () => {
    dispatch(updateOrganization(selectedItem))
    dispatch(updateOpenModalEditOrganization(true))
  }

  const handleOpenModalDelete = () => {
    dispatch(updateOrganization(selectedItem))
    dispatch(updateOpenModalDeleteOrganization(true))
  }

  const onCellClick = (record: any) => {
    if (record) {
      dispatch(updateOrganization(record))
      dispatch(updateOpenDrawerOrganization(true))
    }
  }

  const handleTableChange = async (
    pagination: any,
    filters: any,
    sorter: any,
  ) => {
    dispatch(
      updateOrganizationTableParams({
        pagination,
        filters,
        ...sorter,
      }),
    )
    //
    const token = await GETJwtToken()
    triggerGetOrganizationsTable({
      page: pagination.current,
      limit: pagination.pageSize,
      token,
    })
  }

  const refreshTable = async () => {
    dispatch(
      updateOrganizationTableParams({
        pagination: {
          current: 1,
          pageSize: 10,
          total: organizationTableParams.pagination.total,
        },
        filters: {},
        sorter: {},
      }),
    )
    //
    const token = await GETJwtToken()
    triggerGetOrganizationsTable({
      page: 1,
      limit: 10,
      token,
    })
  }

  const getInitialData = async () => {
    const token = await GETJwtToken()
    triggerGetOrganizationsTable({
      page: organizationTableParams.pagination.current,
      limit: organizationTableParams.pagination.pageSize,
      token,
    })
  }

  // ************************************************* */
  // USE EFFECT ************************************** */
  useEffect(() => {
    getInitialData()
  }, [])

  useEffect(() => {
    if (data && data.data && data.data.length > 0) {
      dispatch(
        updateOrganizationTableParams({
          ...organizationTableParams,
          pagination: {
            ...organizationTableParams.pagination,
            total: data.total,
          },
        }),
      )
      setOrganizations(data.data)
    }
  }, [data])

  useEffect(() => {
    if (isError) {
      setOrganizations([])
    }
  }, [isError])

  useEffect(() => {
    if (organizationRefreshTable) {
      refreshTable()
    }
  }, [organizationRefreshTable])

  // ************************************************ */
  // COMPONENT ************************************** */
  return (
    <div className="mt-3">
      {!isError ? (
        <Table
          rowClassName={(record, index) =>
            index % 2 === 0
              ? 'table-row-light custom-row-hover'
              : 'table-row-dark custom-row-hover'
          }
          className="customTable"
          dataSource={organizations}
          loading={isLoading || isFetching}
          rowKey={(record) => record.id}
          size="middle"
          pagination={organizationTableParams.pagination}
          onChange={handleTableChange}
        >
          <Column
            onCell={(record) => ({
              onClick: () => {
                onCellClick(record)
              },
            })}
            title={t('organizationManagement.organizationName')}
            dataIndex="name"
            key="name"
            className="table__header"
            width="200px"
            filterSearch
            render={(text) => (
              <>
                <div className="table__ellipsis__200px">{text}</div>
              </>
            )}
          />
          <Column
            onCell={(record) => ({
              onClick: () => {
                onCellClick(record)
              },
            })}
            title={t('organizationManagement.type')}
            dataIndex="type"
            key="type"
            width="150px"
            filters={[
              {
                text: 'Master',
                value: 'MASTER',
              },
              {
                text: 'Organization',
                value: 'ORG',
              },
              {
                text: 'Sub Organization',
                value: 'SUBORG',
              },
            ]}
            render={(text) => {
              if (text === 'SUBORG') {
                return (
                  <>
                    <div className="table__ellipsis__200px">
                      <span>Sub Organization</span>
                    </div>
                  </>
                )
              }
              if (text === 'MASTER') {
                return (
                  <>
                    <div className="table__ellipsis__200px">
                      <span>Master</span>
                    </div>
                  </>
                )
              }
              if (text === 'ORG') {
                return (
                  <>
                    <div className="table__ellipsis__200px">
                      <span>Organization</span>
                    </div>
                  </>
                )
              }
              return true
            }}
          />
          <Column
            onCell={(record) => ({
              onClick: () => {
                onCellClick(record)
              },
            })}
            title={t('organizationManagement.address')}
            dataIndex="address"
            key="address"
            width="200px"
            render={(text) => (
              <>
                <div className="table__ellipsis__300px">{text}</div>
              </>
            )}
          />
          <Column
            onCell={(record) => ({
              onClick: () => {
                onCellClick(record)
              },
            })}
            title={t('organizationManagement.country')}
            dataIndex="country"
            key="country"
            width="200px"
            render={(text) => (
              <>
                <div className="table__ellipsis__200px">{text}</div>
              </>
            )}
          />
          <Column
            title={t('organizationManagement.actions')}
            key="action"
            width="50px"
            render={(_: any, record: any) => (
              <Dropdown overlay={menu} trigger={['click']}>
                <Space
                  className="generalStyles__hoverCursor"
                  onClick={() => {
                    setSelectedItem(record)
                  }}
                >
                  <FontAwesomeIcon icon={faEllipsis} />
                </Space>
              </Dropdown>
            )}
          />
        </Table>
      ) : (
        <Alert
          message={t('organizationManagement.errorDescription.error')}
          description={t('organizationManagement.errorDescription.text1')}
          type="error"
          showIcon
          className="mt-3"
        />
      )}
    </div>
  )
}

export default OrganizationTable
