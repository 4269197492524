import React from 'react'
// COMPONENTS
import OrganizationTable from './OrganizationsTable'
import OrganizationsHeader from './OrganizationsHeader'
import OrganizationsCreateOrganizationForm from './OrganizationsCreateOrganizationForm'
import OrganizationsEditOrganizationForm from './OrganizationsEditOrganizationForm'
import OrganizationsDeleteOrganizationModal from './OrganizationsDeleteOrganizationModal'
import OrganizationDrawer from './OrganizationDrawer'

// eslint-disable-next-line arrow-body-style
const OrganizationPage = () => {
  // ************************************************ */
  // LOCAL STORAGE AND VARIABLES ******************** */

  // ************************************************* */
  // USE EFFECT ************************************** */

  // ************************************************ */
  // COMPONENT ************************************** */
  return (
    <div>
      <div className="generalStyles__pageBox">
        <OrganizationsHeader />
        <OrganizationTable />
        <OrganizationsCreateOrganizationForm />
        <OrganizationsEditOrganizationForm />
        <OrganizationsDeleteOrganizationModal />
        <OrganizationDrawer />
      </div>
    </div>
  )
}

export default OrganizationPage
