import React, { useEffect, useState } from 'react'
// ANT DESIGN COMPONENTS
import { Divider, Drawer, Tag } from 'antd'
// I18N TRANSLATION
import { useTranslation } from 'react-i18next'
// FONT AWESOME LIBRYARY AND ICONS
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faUser,
  faBuilding,
  faBuildingLock,
  faCheckCircle,
  faCircleExclamation,
  faBan,
  faUserShield,
} from '@fortawesome/free-solid-svg-icons'
// REDUX
import { useDispatch, useSelector } from 'react-redux'
import { updateOpenDrawerRole } from '../../redux/roles/roleSlice'

library.add(
  faUser,
  faBuilding,
  faBuildingLock,
  faCheckCircle,
  faCircleExclamation,
  faBan,
  faUserShield,
)

function RolesDrawer() {
  // ************************************************ */
  // LOCAL STORAGE AND VARIABLES ******************** */
  const dispatch = useDispatch()
  const { role, openDrawerRole } = useSelector((state: any) => state.role)
  const [data, setData] = useState<any>({})
  const [t] = useTranslation('global')
  // ************************************************ */
  // FUNCTIONS ************************************** */
  const onClose = () => {
    dispatch(updateOpenDrawerRole(false))
  }

  // ************************************************* */
  // USE EFFECT ************************************** */
  useEffect(() => {
    if (role) {
      setData(role)
    }
  }, [role])

  // ************************************************ */
  // COMPONENT ************************************** */
  return (
    <Drawer
      width="35%"
      placement="right"
      onClose={onClose}
      closable={false}
      visible={openDrawerRole}
    >
      {data && (
        <div>
          {/** ************************************************* */}
          <div>
            <h5>
              <p className="generalStyles__text">
                <FontAwesomeIcon
                  style={{ marginRight: '10px' }}
                  icon={faUser}
                  className="generalStyles__info"
                />
                {t('organizationManagement.roleInformation')}
              </p>
            </h5>
            <Divider />
          </div>
          {/* -*-*-*-*-*-*-*-*-*-*-*-*-*-*- */}
          <div className="generalStyles__flex">
            <div className="yourProfile__drawerContainerText1">
              <p className="drawer__title">ID:</p>
            </div>
            <div className="yourProfile__drawerContainerText2">
              <p className="generalStyles__text">{data.id ? data.id : '--'}</p>
            </div>
          </div>
          {/* -*-*-*-*-*-*-*-*-*-*-*-*-*-*- */}
          <div className="generalStyles__flex">
            <div className="yourProfile__drawerContainerText1">
              <p className="drawer__title">
                {t('organizationManagement.name')}
              </p>
            </div>
            <div className="yourProfile__drawerContainerText2">
              <p className="generalStyles__text">
                {data.name ? data.name : '--'}
              </p>
            </div>
          </div>
          {/* -*-*-*-*-*-*-*-*-*-*-*-*-*-*- */}
          <div className="generalStyles__flex">
            <div className="yourProfile__drawerContainerText1">
              <p className="drawer__title">
                {t('organizationManagement.status')}
              </p>
            </div>
            <div className="yourProfile__drawerContainerText2">
              <p className="generalStyles__text">
                {data.active ? (
                  <Tag
                    color="success"
                    icon={
                      <FontAwesomeIcon
                        icon={faCheckCircle}
                        style={{ marginRight: '5px' }}
                      />
                    }
                    style={{ width: '90px' }}
                  >
                    {t('organizationManagement.active')}
                  </Tag>
                ) : (
                  <Tag
                    color="error"
                    icon={
                      <FontAwesomeIcon
                        icon={faBan}
                        style={{ marginRight: '5px' }}
                      />
                    }
                    style={{ width: '90px' }}
                  >
                    {t('organizationManagement.locked')}
                  </Tag>
                )}{' '}
              </p>
            </div>
          </div>
          <br />
          <br />
          {/** ************************************************* */}
          {/** ************************************************* */}
          <div>
            <h5>
              <p className="generalStyles__text">
                <FontAwesomeIcon
                  style={{ marginRight: '10px' }}
                  icon={faUserShield}
                  className="generalStyles__info"
                />
                {t('organizationManagement.permissions')}
              </p>
            </h5>
            <Divider />
          </div>
          <br />
          <br />
          {/** ************************************************* */}
          {/** ************************************************* */}
          <div>
            <h5>
              <p className="generalStyles__text">
                <FontAwesomeIcon
                  style={{ marginRight: '10px' }}
                  icon={faBuilding}
                  className="generalStyles__info"
                />
                {t('organizationManagement.organizationInformation')}
              </p>
            </h5>
            <Divider />
          </div>
          {/* -*-*-*-*-*-*-*-*-*-*-*-*-*-*- */}
          <div className="generalStyles__flex">
            <div className="yourProfile__drawerContainerText1">
              <p className="drawer__title">
                {t('organizationManagement.organizationId')}
              </p>
            </div>
            <div className="yourProfile__drawerContainerText2">
              <p className="generalStyles__text">--</p>
            </div>
          </div>
          {/* -*-*-*-*-*-*-*-*-*-*-*-*-*-*- */}
          <div className="generalStyles__flex">
            <div className="yourProfile__drawerContainerText1">
              <p className="drawer__title">
                {t('organizationManagement.name')}
              </p>
            </div>
            <div className="yourProfile__drawerContainerText2">
              <p className="generalStyles__text">--</p>
            </div>
          </div>
          {/* -*-*-*-*-*-*-*-*-*-*-*-*-*-*- */}
          <div className="generalStyles__flex">
            <div className="yourProfile__drawerContainerText1">
              <p className="drawer__title">
                {t('organizationManagement.type')}
              </p>
            </div>
            <div className="yourProfile__drawerContainerText2">
              <p className="generalStyles__text">-- </p>
            </div>
          </div>
          {/* -*-*-*-*-*-*-*-*-*-*-*-*-*-*- */}
          <div className="generalStyles__flex">
            <div className="yourProfile__drawerContainerText1">
              <p className="drawer__title">-- </p>
            </div>
            <div className="yourProfile__drawerContainerText2">
              <p className="generalStyles__text">-- </p>
            </div>
          </div>
          {/* -*-*-*-*-*-*-*-*-*-*-*-*-*-*- */}
          <div className="generalStyles__flex">
            <div className="yourProfile__drawerContainerText1">
              <p className="drawer__title">-- </p>
            </div>
            <div className="yourProfile__drawerContainerText2">
              <p className="generalStyles__text">-- </p>
            </div>
          </div>
          {/* -*-*-*-*-*-*-*-*-*-*-*-*-*-*- */}
          <div className="generalStyles__flex">
            <div className="yourProfile__drawerContainerText1">
              <p className="drawer__title">-- </p>
            </div>
            <div className="yourProfile__drawerContainerText2">
              <p className="generalStyles__text">
                {data.state ? data.state : '--'}
              </p>
            </div>
          </div>
          {/* -*-*-*-*-*-*-*-*-*-*-*-*-*-*- */}
          <div className="generalStyles__flex">
            <div className="yourProfile__drawerContainerText1">
              <p className="drawer__title">-- </p>
            </div>
            <div className="yourProfile__drawerContainerText2">
              <p className="generalStyles__text">-- </p>
            </div>
          </div>
          {/* -*-*-*-*-*-*-*-*-*-*-*-*-*-*- */}
          <div className="generalStyles__flex">
            <div className="yourProfile__drawerContainerText1">
              <p className="drawer__title">-- </p>
            </div>
            <div className="yourProfile__drawerContainerText2">
              <p className="generalStyles__text">-- </p>
            </div>
          </div>
          {/* -*-*-*-*-*-*-*-*-*-*-*-*-*-*- */}
          <div className="generalStyles__flex">
            <div className="yourProfile__drawerContainerText1">
              <p className="drawer__title">-- </p>
            </div>
            <div className="yourProfile__drawerContainerText2">
              <p className="generalStyles__text">-- </p>
            </div>
          </div>
        </div>
      )}
    </Drawer>
  )
}

export default RolesDrawer
