import React, { useEffect, useState } from 'react'
// ANT DESIGN COMPONENTS
import { Modal, Button, Input, Spin } from 'antd'
// I18N TRANSLATION
import { useTranslation } from 'react-i18next'
// FONT AWESOME LIBRYARY AND ICONS
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faTrash,
  faTriangleExclamation,
} from '@fortawesome/free-solid-svg-icons'
// REDUX
import { useDispatch, useSelector } from 'react-redux'
import {
  useBlockUserMutation,
  useUnBlockUserMutation,
} from '../../redux/users/userAPI'
// AUTHORIZATION
import GETJwtToken from '../../redux/authentication'
import {
  updateOpenModalBlockUnBlockUser,
  updateUserAlert,
} from '../../redux/users/userSlice'

library.add(faTrash, faTriangleExclamation)

function UsersBlockUnBlockModal() {
  // ************************************************ */
  // LOCAL STORAGE AND VARIABLES ******************** */
  const dispatch = useDispatch()
  const { user, openModalBlockUnBlockUser } = useSelector(
    (state: any) => state.user,
  )
  const [
    disableButtonConfirmBlockUnBlockUser,
    setDisableButtonConfirmBlockUnBlockUser,
  ] = useState(true)
  const [confirmUserName, setConfirmUserName] = useState('')
  const [
    triggerBlockUser,
    {
      isLoading: isLoadingBlockUser,
      isSuccess: isSuccessBlockUser,
      isError: isErrorBlockUser,
      reset: resetBlockUser,
    },
  ] = useBlockUserMutation()
  const [
    triggerUnBlockUser,
    {
      isLoading: isLoadingUnBlockUser,
      isSuccess: isSuccessUnBlockUser,
      isError: isErrorUnBlockUser,
      reset: resetUnBlockUser,
    },
  ] = useUnBlockUserMutation()

  const [t] = useTranslation('global')

  // ************************************************ */
  // FUNCTIONS ************************************** */
  const handleOk = () => {
    dispatch(updateOpenModalBlockUnBlockUser(false))
  }

  const handleCancel = () => {
    dispatch(updateOpenModalBlockUnBlockUser(false))
  }

  const handleCloseModal = () => {
    dispatch(updateOpenModalBlockUnBlockUser(false))
  }

  const handleDeleteItem = async () => {
    const token = await GETJwtToken()
    if (user && user.status === 'ACTIVE') {
      triggerBlockUser({ id: user.id, token })
    } else {
      triggerUnBlockUser({ id: user.id, token })
    }
  }

  const handleSubmitDeleteItem = async (event: any) => {
    if (event.key === 'Enter') {
      const token = await GETJwtToken()
      if (user && user.status === 'ACTIVE') {
        triggerBlockUser({ id: user.id, token })
      } else {
        triggerUnBlockUser({ id: user.id, token })
      }
    }
  }

  // ************************************************* */
  // USE EFFECT ************************************** */
  useEffect(() => {
    if (openModalBlockUnBlockUser) {
      setDisableButtonConfirmBlockUnBlockUser(true)
      setConfirmUserName('')
      resetBlockUser()
      resetUnBlockUser()
    }
  }, [openModalBlockUnBlockUser])

  useEffect(() => {
    setDisableButtonConfirmBlockUnBlockUser(true)
    let firstName = ''
    let middleName = ''
    let lastName = ''
    if (user.first_name) {
      firstName = user.first_name
    }
    if (user.middle_name) {
      middleName = ` ${user.middle_name}`
    }
    if (user.last_name) {
      lastName = ` ${user.last_name}`
    }
    const userName = firstName + middleName + lastName
    //
    if (
      confirmUserName &&
      confirmUserName.toLowerCase() === userName.toLowerCase()
    ) {
      setDisableButtonConfirmBlockUnBlockUser(false)
    } else {
      setDisableButtonConfirmBlockUnBlockUser(true)
    }
  }, [confirmUserName])

  useEffect(() => {
    let messageType = 'Activated'
    if (user && user.status && user.status !== 'ACTIVE') {
      messageType = 'Blocked'
    }
    if (isSuccessBlockUser || isSuccessUnBlockUser) {
      setTimeout(() => {
        dispatch(
          updateUserAlert({
            title: 'Success',
            description: `User ${messageType} successfully`,
            status: 'success',
          }),
        )
      }, 150)
      dispatch(updateOpenModalBlockUnBlockUser(false))
    }
    if (isErrorBlockUser || isErrorUnBlockUser) {
      setTimeout(() => {
        dispatch(
          updateUserAlert({
            title: 'Error',
            description:
              'Something went wrong! Please reload the page and try again.',
            status: 'error',
          }),
        )
      }, 150)
      dispatch(updateOpenModalBlockUnBlockUser(false))
    }
  }, [
    isSuccessBlockUser,
    isSuccessUnBlockUser,
    isErrorBlockUser,
    isErrorUnBlockUser,
  ])

  // ************************************************ */
  // COMPONENT ************************************** */
  return (
    <Modal
      onOk={handleOk}
      closable={false}
      onCancel={handleCancel}
      visible={openModalBlockUnBlockUser}
      footer={null}
    >
      <Spin spinning={isLoadingBlockUser || isLoadingUnBlockUser}>
        <div>
          <div className="text-center">
            <FontAwesomeIcon
              icon={faTriangleExclamation}
              className="generalStyles__warning generalStyles__deleteItemIcon"
            />
            <p className="mt-3 generalStyles__text">
              {t('userManagement.deleteDescription.text1')}
              <b className="generalStyles__warning">
                {t('userManagement.deleteDescription.text2')}{' '}
              </b>
              {t('userManagement.deleteDescription.text3')}
              <b className="generalStyles__warning">
                {user.first_name} {user.middle_name} {user.last_name}{' '}
              </b>
              {t('userManagement.deleteDescription.text4')}
            </p>
            <Input
              placeholder={t('userManagement.userName')}
              onChange={(e) => {
                setConfirmUserName(e.target.value)
              }}
              value={confirmUserName}
              onKeyDown={handleSubmitDeleteItem}
              status="warning"
            />
          </div>
          <div className="generalStyles__flexEnd mt-4">
            <div>
              <Button
                htmlType="submit"
                className={
                  disableButtonConfirmBlockUnBlockUser
                    ? 'buttonStyle__inactive__1'
                    : 'buttonStyle__2'
                }
                style={{ marginRight: '10px' }}
                onClick={handleDeleteItem}
                disabled={disableButtonConfirmBlockUnBlockUser}
              >
                {t('userManagement.delete')}
              </Button>
              <Button onClick={handleCloseModal} type="default">
                {t('userManagement.close')}
              </Button>
            </div>
          </div>
        </div>
      </Spin>
    </Modal>
  )
}

export default UsersBlockUnBlockModal
