import React, { useEffect, useState } from 'react'
// AMPLIFY
import { Auth } from 'aws-amplify'
import QRCode from 'qrcode.react'
// COPY TO CLIPBOARD
// ANT DESIGN COMPONENTS
import { Input, Button } from 'antd'
// FONT AWESOME LIBRYARY AND ICONS
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faKey,
  faMobileScreen,
  faCopy,
} from '@fortawesome/free-solid-svg-icons'
// REDUX
import { useDispatch, useSelector } from 'react-redux'
import {
  updateAmplifyActiveComponent,
  updateIsLoading,
} from '../../redux/login/loginSlice'

library.add(faKey, faMobileScreen, faCopy)

function CustomAmplifyMFASetUp() {
  // ************************************************ */
  // LOCAL STORAGE AND VARIABLES ******************** */
  const dispatch = useDispatch()
  const { user } = useSelector((state: any) => state.login)
  const [totpToken, setTotpToken] = useState('')
  const [qrCode, setQrCode] = useState('')
  const [errorMessage, setErrorMessage] = useState<any>('')
  // eslint-disable-next-line no-unused-vars
  const [codeText, setCodeText] = useState('')

  // ************************************************ */
  // FUNCTIONS ************************************** */
  const handleSubmitSetupTOTP = async () => {
    const response = await Auth.setupTOTP(user)
    if (response) {
      setCodeText(response)
      setQrCode(
        `otpauth://totp/AWSCognito:${user.username}?secret=${response}&issuer=`,
      )
    }
  }

  const handleSubmitVerifyTTotpToken = () => {
    dispatch(updateIsLoading(true))
    Auth.verifyTotpToken(user, totpToken)
      .then((response: any) => {
        Auth.setPreferredMFA(user, 'TOTP')
        if (response && response.Status && response.Status === 'SUCCESS') {
          dispatch(updateIsLoading(false))
        }
      })
      .catch((e) => {
        setErrorMessage(e.message)
        dispatch(updateIsLoading(false))
      })
  }

  const handleSubmitVerifyTTotpTokenEnter = (event: any) => {
    if (event.key === 'Enter') {
      handleSubmitVerifyTTotpToken()
    }
  }

  // ************************************************* */
  // USE EFFECT ************************************** */
  useEffect(() => {
    handleSubmitSetupTOTP()
  }, [])

  // ************************************************ */
  // COMPONENT ************************************** */
  return (
    <div>
      <div className="generalStyles__flex mt-3">
        <div className="loginStyles__qrCode">
          <QRCode value={qrCode} />
          <div className="loginStyles__textCodeContainer">
            <span>
              <FontAwesomeIcon className="generalStyles__info" icon={faCopy} />{' '}
              Copy Text Code
            </span>
          </div>
        </div>
        <div>
          <div className="generalStyles__flexCenter">
            <div>
              <FontAwesomeIcon
                className="styles__mobileIcon"
                icon={faMobileScreen}
              />
            </div>
          </div>
          <div>
            <p className="styles__MFADescription">
              To complete the enrollment process, scan the barcode with an
              authenticator app such as Authy or Google Authenticator. This
              action is required only the first time you singing in
            </p>
          </div>
        </div>
      </div>
      {/* INPUT - TOTP TOKEN */}
      <Input
        className="loginStyles__mobileInput mt-4"
        placeholder="TOTP Token"
        prefix={
          <FontAwesomeIcon
            className="generalStyles__info "
            icon={faKey}
            style={{ marginRight: '10px' }}
          />
        }
        onChange={(e) => {
          setTotpToken(e.target.value)
        }}
        value={totpToken}
        onKeyDown={handleSubmitVerifyTTotpTokenEnter}
      />

      {/** BUTTON - LOGIN */}
      <div className="generalStyles__flex mt-4">
        <div>
          <Button
            disabled={totpToken.length === 0}
            onClick={handleSubmitVerifyTTotpToken}
          >
            Verify Token
          </Button>
        </div>
        <div style={{ marginLeft: '10px' }}>
          <Button
            className="buttonStyle__2 loginStyles__buttonActive"
            onClick={() => {
              dispatch(updateAmplifyActiveComponent('sign-in'))
            }}
          >
            <span>Sign in With Another Account</span>
          </Button>
        </div>
      </div>
      <p className="loginStyle__errorMessage">{errorMessage}</p>
    </div>
  )
}

export default CustomAmplifyMFASetUp
