import React, { useEffect, useState } from 'react'
// ANT DESIGN COMPONENTS
import { Form, Spin, Divider, Drawer, Input, Button, Select } from 'antd'
// I18N TRANSLATION
import { useTranslation } from 'react-i18next'
// FONT AWESOME LIBRYARY AND ICONS
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faUser,
  faBuildingCircleCheck,
} from '@fortawesome/free-solid-svg-icons'
// REDUX
import { useDispatch, useSelector } from 'react-redux'
import {
  updateOpenModalCreateNewRole,
  updateRoleAlert,
} from '../../redux/roles/roleSlice'
import { usePostRoleMutation } from '../../redux/roles/roleAPI'
import { useLazyGetOrganizationAndChildsQuery } from '../../redux/organizations/organizationAPI'
import { updateOrganizationAndChilds } from '../../redux/organizations/organizationSlice'
// AUTHORIZATION
import GETJwtToken from '../../redux/authentication'
// STYLES

library.add(faUser, faBuildingCircleCheck)
const { Option } = Select

function RolesCreateRoleForm() {
  // ************************************************ */
  // LOCAL STORAGE AND VARIABLES ******************** */
  const dispatch = useDispatch()
  const { openModalCreateNewRole } = useSelector((state: any) => state.role)
  const { organizationAndChilds } = useSelector(
    (state: any) => state.organization,
  )
  const [form] = Form.useForm()
  // eslint-disable-next-line no-unused-vars
  const [idOrganizationSelected, setIdOrganizationSelected] = useState('')
  const masterID = '4f594f28-9896-4ffb-9e40-0693c06bde69'
  const [listOrganizations, setListOrganizations] = useState<any[]>([])
  const [t] = useTranslation('global')
  const [
    postRole,
    {
      isSuccess: isSuccessPostRole,
      isLoading: isLoadingPostRole,
      isError: isErrorPostRole,
      reset: resetPostRole,
    },
  ] = usePostRoleMutation()
  const [
    triggerGetOrganizationAndChilds,
    { isError: isErrorGetOrganizationAndChilds },
  ] = useLazyGetOrganizationAndChildsQuery()

  // ************************************************ */
  // FUNCTIONS ************************************** */
  const cleanOrganizationsList = () => {
    dispatch(
      updateOrganizationAndChilds({
        childs: [],
      }),
    )
  }

  const onClose = () => {
    cleanOrganizationsList()
    dispatch(updateOpenModalCreateNewRole(false))
  }

  const onFinish = async (values: any) => {
    const token = await GETJwtToken()
    const MENU = {
      name: 'ORIGIN MENU',
      active: true,
      pages: [
        {
          name: 'tools',
          active: true,
          sub_pages: [],
          actions: [],
        },
      ],
    }
    const BODY = {
      token,
      body: {
        active: true,
        menu: JSON.stringify(MENU),
        name: values.name,
      },
    }
    postRole(BODY)
  }

  const handleCloseModal = () => {
    cleanOrganizationsList()
    dispatch(updateOpenModalCreateNewRole(false))
  }

  const getOrganizationAndChilds = async (orgId: string) => {
    const token = await GETJwtToken()
    const data = await triggerGetOrganizationAndChilds({
      orgId,
      token,
    }).unwrap()
    if (
      data &&
      data.data &&
      data.data.children &&
      data.data.children.length > 0
    ) {
      const BODY = {
        childs: [
          ...organizationAndChilds.childs,
          {
            level: organizationAndChilds.childs.length,
            data: data.data.children,
          },
        ],
      }
      dispatch(updateOrganizationAndChilds(BODY))
    }
  }

  const handleChangeOrganization = (value: string, level: number) => {
    const totalChilds = organizationAndChilds.childs.length
    // if we select a new value
    if (value) {
      setIdOrganizationSelected(value)
      getOrganizationAndChilds(value)
    }
    // if we select the last value of the list
    else if (totalChilds === level) {
      setIdOrganizationSelected(
        organizationAndChilds.childs[level - 1].orgSelected,
      )
      form.setFieldsValue({ [`assignToOrganization${level}`]: undefined })
    }
    // if we select a middle value
    else {
      const newArray = []
      setIdOrganizationSelected(
        organizationAndChilds.childs[level - 1].orgSelected,
      )
      for (let index = 0; index < level; index += 1) {
        newArray.push(organizationAndChilds.childs[index])
      }
      for (let index = level + 1; index <= totalChilds; index += 1) {
        form.setFieldsValue({ [`assignToOrganization${index}`]: undefined })
      }
      const BODY = {
        childs: newArray,
      }
      dispatch(updateOrganizationAndChilds(BODY))
    }
  }

  // ************************************************* */
  // USE EFFECT ************************************** */
  useEffect(() => {
    if (openModalCreateNewRole) {
      resetPostRole()
      form.resetFields()
      cleanOrganizationsList()
      getOrganizationAndChilds(masterID)
    }
  }, [openModalCreateNewRole])

  useEffect(() => {
    if (isSuccessPostRole) {
      setTimeout(() => {
        dispatch(
          updateRoleAlert({
            title: 'Success',
            description: 'Role created successfully',
            status: 'success',
          }),
        )
      }, 150)
      cleanOrganizationsList()
      dispatch(updateOpenModalCreateNewRole(false))
    }
    if (isErrorPostRole) {
      setTimeout(() => {
        dispatch(
          updateRoleAlert({
            title: 'Error',
            description:
              'Something went wrong! Please reload the page and try again.',
            status: 'error',
          }),
        )
      }, 150)
      cleanOrganizationsList()
      dispatch(updateOpenModalCreateNewRole(false))
    }
  }, [isSuccessPostRole, isErrorPostRole])

  useEffect(() => {
    if (isErrorGetOrganizationAndChilds) {
      setTimeout(() => {
        dispatch(
          updateRoleAlert({
            title: 'Error',
            description:
              'Something went wrong! Please reload the page and try again.',
            status: 'error',
          }),
        )
      }, 150)
      cleanOrganizationsList()
      dispatch(updateOpenModalCreateNewRole(false))
    }
  }, [isErrorGetOrganizationAndChilds])

  useEffect(() => {
    if (organizationAndChilds && organizationAndChilds.childs) {
      setListOrganizations(organizationAndChilds.childs)
    }
  }, [organizationAndChilds])

  // ************************************************ */
  // COMPONENT ************************************** */
  return (
    <Drawer
      width="35%"
      placement="right"
      onClose={onClose}
      closable={false}
      visible={openModalCreateNewRole}
    >
      <Spin spinning={isLoadingPostRole} tip={t('general.loading')}>
        <div>
          <h5>
            <FontAwesomeIcon
              style={{ marginRight: '10px' }}
              icon={faBuildingCircleCheck}
              className="generalStyles__info"
            />
            <span className="generalStyles__text">
              {t('organizationManagement.createNewRole')}
            </span>
          </h5>
          <Divider />
        </div>
        <Form form={form} layout="vertical" onFinish={onFinish}>
          <div className="container">
            <div className="row">
              <div className="col-12">
                {/** ---------------------------------------------------- */}
                {/** ASSIGN TO ORGANIZATION */}
                {listOrganizations.map((organization: any) => (
                  <Form.Item
                    key={organization.level}
                    name={`assignToOrganization${organization.level}`}
                    label={
                      <>
                        <span className="generalStyles__text">
                          {organization.level === 0 ? (
                            <>
                              {t('organizationManagement.assignToOrganization')}
                            </>
                          ) : (
                            <>{`${t(
                              'organizationManagement.subOrganization',
                            )}`}</>
                          )}
                        </span>
                      </>
                    }
                    rules={[
                      {
                        // required: organization.level === 0,
                        message:
                          organization.level === 0
                            ? t(
                                'organizationManagement.formRequiredMessage.organization',
                              )
                            : t(
                                'organizationManagement.formRequiredMessage.subOrganization',
                              ),
                      },
                    ]}
                  >
                    <Select
                      disabled
                      showSearch
                      optionFilterProp="children"
                      onChange={(value) => {
                        handleChangeOrganization(value, organization.level)
                      }}
                      allowClear
                      filterOption={(input: any, option: any) =>
                        option.children
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                    >
                      {organization.data.map((item: any) => (
                        <Option key={item.id} value={item.id}>
                          {item.name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                ))}
              </div>
              <div className="col-12">
                {/** ---------------------------------------------------- */}
                {/** NAME */}
                <Form.Item
                  name="name"
                  label={
                    <>
                      <span className="generalStyles__text">
                        {t('organizationManagement.roleName')}
                      </span>
                    </>
                  }
                  rules={[
                    {
                      required: true,
                      message: t(
                        'organizationManagement.formRequiredMessage.roleName',
                      ),
                    },
                  ]}
                >
                  <Input className="generalStyles__input" />
                </Form.Item>
              </div>
            </div>
          </div>
          {/** ---------------------------------------------------- */}
          <div className="mt-4">
            <div>
              <Button
                htmlType="submit"
                disabled={isLoadingPostRole}
                style={{ marginRight: '10px' }}
                type="primary"
              >
                {t('organizationManagement.submit')}
              </Button>
              <Button type="default" onClick={handleCloseModal}>
                {t('organizationManagement.close')}
              </Button>
            </div>
          </div>
        </Form>
      </Spin>
    </Drawer>
  )
}

export default RolesCreateRoleForm
