import React, { useState, useEffect } from 'react'
import { Navigate } from 'react-router-dom'
// ANT DESIGN COMPONENTS
import { Spin } from 'antd'
// AMPLIFY
import { Auth, Hub } from 'aws-amplify'
// REDUX
import { useDispatch, useSelector } from 'react-redux'
import {
  updateLoginAmplify,
  updateJwtToken,
} from '../../redux/login/loginSlice'
// CUSTOM AMPLIFY COMPONENTS
import CustomAmplifySignIn from './CustomAmplifySignIn'
import CustomAmplifyForgotPassword from './CustomAmplifyForgotPassword'
import CustomAmplifyMFASetUp from './CustomAmplifyMFASetUp'
import CustomAmplifyCarousel from './CustomAmplifyCarousel'
import CustomAmplifyForceChangePassword from './CustomAmplifyForceChangePassword'
// STYLES
import logo from '../../assets/LiftNetLogo.png'
import '../../styles/generalStyles.scss'

const LoginPage = () => {
  // ************************************************ */
  // LOCAL STORAGE AND VARIABLES ******************** */
  const dispatch = useDispatch()
  const { amplifyActiveComponent, isLoading } = useSelector(
    (state: any) => state.login,
  )
  const [launchToDashboard, setLaunchToDashboard] = useState(false)

  // ************************************************ */
  // FUNCTIONS ************************************** */
  const successLogin = () => {
    localStorage.setItem('headerTitle', 'home')
    Auth.currentSession().then((data) => {
      const jwt = data.getIdToken()
      dispatch(updateJwtToken(`Bearer ${jwt.getJwtToken()}`))
    })
  }
  const checkUserLogged = async () => {
    try {
      await Auth.currentAuthenticatedUser()
      dispatch(updateLoginAmplify(true))
      localStorage.setItem('useLoggedAmplify', 'true')
      setLaunchToDashboard(true)
      return true
    } catch {
      dispatch(updateLoginAmplify(false))
      localStorage.setItem('useLoggedAmplify', 'false')
      setLaunchToDashboard(false)
      return false
    }
  }
  const launchLogin = async () => {
    await checkUserLogged()
  }

  // ************************************************ */
  // USE EFFECT ************************************* */

  useEffect(() => {
    launchLogin()
  }, [])

  useEffect(() => {
    Hub.listen('auth', (authData) => {
      if (authData && authData.payload && authData.payload.event) {
        if (authData.payload.event === 'signIn_failure') {
          dispatch(updateLoginAmplify(false))
          localStorage.setItem('useLoggedAmplify', 'false')
          setLaunchToDashboard(false)
        } else if (authData.payload.event === 'signIn') {
          dispatch(updateLoginAmplify(true))
          localStorage.setItem('useLoggedAmplify', 'true')
          setLaunchToDashboard(true)
        }
      }
    })
  })

  // ************************************************ */
  // COMPONENT ************************************** */
  return (
    <>
      {launchToDashboard ? (
        <>
          {successLogin()}
          {<Navigate to="/" />}
        </>
      ) : (
        <div className="loginStyles__body">
          <div className="loginStyles__loginSubBox1 d-none d-sm-block">
            <CustomAmplifyCarousel />
          </div>
          <div className="loginStyles__loginSubBox2">
            <Spin
              spinning={isLoading}
              wrapperClassName="loginStyle__spinContainer"
            >
              <div className="loginStyles__loginMainBox">
                {/** LOGO */}
                <div className="generalStyles__flex d-block d-sm-none">
                  <div>
                    <img
                      src={logo}
                      className="loginStyles__liftNetLogo"
                      alt=""
                      width="150"
                      height="32"
                    />
                  </div>
                </div>
                {/** DESCRIPTION */}
                <div className=" d-none d-sm-block">
                  <p className="loginStyles__homeLogin">Login to Lift-Net</p>
                  <p
                    className="loginStyles__homeLogin"
                    style={{ marginTop: '-20px', marginBottom: '20px' }}
                  >
                    Monitoring application
                  </p>
                </div>
                {/* ********** LOGIN ********** */}
                {amplifyActiveComponent === 'sign-in' && (
                  <CustomAmplifySignIn />
                )}
                {/* ********** FORGOT PASSWORD ********** */}
                {amplifyActiveComponent === 'forgot-password' && (
                  <CustomAmplifyForgotPassword />
                )}
                {/* ********** MFA SET-UP ********** */}
                {amplifyActiveComponent === 'mfa-setup' && (
                  <CustomAmplifyMFASetUp />
                )}
                {/* ********** FORCE CHANGE PASSWORD ********** */}
                {amplifyActiveComponent === 'force-change-password' && (
                  <CustomAmplifyForceChangePassword />
                )}
              </div>
            </Spin>
          </div>
        </div>
      )}
    </>
  )
}

export default LoginPage
