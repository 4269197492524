import React, { useEffect, useState } from 'react'
// ANT DESIGN COMPONENTS
import { Modal, Button, Input, Spin } from 'antd'
// I18N TRANSLATION
import { useTranslation } from 'react-i18next'
// FONT AWESOME LIBRYARY AND ICONS
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faTrash,
  faTriangleExclamation,
} from '@fortawesome/free-solid-svg-icons'
// REDUX
import { useDispatch, useSelector } from 'react-redux'
import { useResetUserPasswordMutation } from '../../redux/users/userAPI'
// AUTHORIZATION
import GETJwtToken from '../../redux/authentication'
import {
  updateOpenModalResetUserPassword,
  updateUserAlert,
} from '../../redux/users/userSlice'

library.add(faTrash, faTriangleExclamation)

function UsersResetPasswordModal() {
  // ************************************************ */
  // LOCAL STORAGE AND VARIABLES ******************** */
  const dispatch = useDispatch()
  const { user, openModalResetUserPassword } = useSelector(
    (state: any) => state.user,
  )
  const [
    disableButtonConfirmResetUserPassowrd,
    setDisableButtonConfirmResetUserPassword,
  ] = useState(true)
  const [confirmUserName, setConfirmUserName] = useState('')
  const [
    triggerResetUserPassword,
    { isLoading, isSuccess, isError, reset: resetDeleteUser },
  ] = useResetUserPasswordMutation()
  const [t] = useTranslation('global')

  // ************************************************ */
  // FUNCTIONS ************************************** */
  const handleOk = () => {
    dispatch(updateOpenModalResetUserPassword(false))
  }

  const handleCancel = () => {
    dispatch(updateOpenModalResetUserPassword(false))
  }

  const handleCloseModal = () => {
    dispatch(updateOpenModalResetUserPassword(false))
  }

  const handleDeleteItem = async () => {
    const token = await GETJwtToken()
    triggerResetUserPassword({ id: user.id, token })
  }

  const handleSubmitResetUserPassword = async (event: any) => {
    if (event.key === 'Enter') {
      const token = await GETJwtToken()
      triggerResetUserPassword({ id: user.id, token })
    }
  }

  // ************************************************* */
  // USE EFFECT ************************************** */
  useEffect(() => {
    if (openModalResetUserPassword) {
      setDisableButtonConfirmResetUserPassword(true)
      setConfirmUserName('')
      resetDeleteUser()
    }
  }, [openModalResetUserPassword])

  useEffect(() => {
    setDisableButtonConfirmResetUserPassword(true)
    let firstName = ''
    let middleName = ''
    let lastName = ''
    if (user.first_name) {
      firstName = user.first_name
    }
    if (user.middle_name) {
      middleName = ` ${user.middle_name}`
    }
    if (user.last_name) {
      lastName = ` ${user.last_name}`
    }
    const userName = firstName + middleName + lastName
    //
    if (
      confirmUserName &&
      confirmUserName.toLowerCase() === userName.toLowerCase()
    ) {
      setDisableButtonConfirmResetUserPassword(false)
    } else {
      setDisableButtonConfirmResetUserPassword(true)
    }
  }, [confirmUserName])

  useEffect(() => {
    if (isSuccess) {
      setTimeout(() => {
        dispatch(
          updateUserAlert({
            title: 'Success',
            description: 'User password has been reset successfully',
            status: 'success',
          }),
        )
      }, 150)
      dispatch(updateOpenModalResetUserPassword(false))
    }
    if (isError) {
      setTimeout(() => {
        dispatch(
          updateUserAlert({
            title: 'Error',
            description:
              'Something went wrong! Please reload the page and try again.',
            status: 'error',
          }),
        )
      }, 150)
      dispatch(updateOpenModalResetUserPassword(false))
    }
  }, [isSuccess, isError])

  // ************************************************ */
  // COMPONENT ************************************** */
  return (
    <Modal
      onOk={handleOk}
      closable={false}
      onCancel={handleCancel}
      visible={openModalResetUserPassword}
      footer={null}
    >
      <Spin spinning={isLoading}>
        <div>
          <div className="text-center">
            <FontAwesomeIcon
              icon={faTriangleExclamation}
              className="generalStyles__warning generalStyles__deleteItemIcon"
            />
            <p className="mt-3 generalStyles__text">
              {t('userManagement.resetPasswordDescription.text1')}
              <b className="generalStyles__warning">
                {t('userManagement.resetPasswordDescription.text2')}{' '}
              </b>
              {t('userManagement.resetPasswordDescription.text3')}
              <b className="generalStyles__warning">
                {user.first_name} {user.middle_name} {user.last_name}{' '}
              </b>
              {t('userManagement.resetPasswordDescription.text4')}
            </p>
            <Input
              placeholder={t('userManagement.userName')}
              onChange={(e) => {
                setConfirmUserName(e.target.value)
              }}
              value={confirmUserName}
              onKeyDown={handleSubmitResetUserPassword}
              status="warning"
            />
          </div>
          <div className="generalStyles__flexEnd mt-4">
            <div>
              <Button
                htmlType="submit"
                className={
                  disableButtonConfirmResetUserPassowrd
                    ? 'buttonStyle__inactive__1'
                    : 'buttonStyle__2'
                }
                style={{ marginRight: '10px' }}
                onClick={handleDeleteItem}
                disabled={disableButtonConfirmResetUserPassowrd}
              >
                {t('userManagement.resetPassword')}
              </Button>
              <Button onClick={handleCloseModal} type="default">
                {t('userManagement.close')}
              </Button>
            </div>
          </div>
        </div>
      </Spin>
    </Modal>
  )
}

export default UsersResetPasswordModal
