import React, { useEffect, useState } from 'react'
// ANT DESIGN COMPONENTS
import {
  Form,
  Spin,
  Divider,
  Drawer,
  Input,
  Button,
  Select,
  InputNumber,
} from 'antd'
// I18N TRANSLATION
import { useTranslation } from 'react-i18next'
// FONT AWESOME LIBRYARY AND ICONS
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faUser,
  faBuildingCircleCheck,
} from '@fortawesome/free-solid-svg-icons'
// REDUX
import { useDispatch, useSelector } from 'react-redux'
import {
  updateOpenModalCreateNewUser,
  updateUserAlert,
} from '../../redux/users/userSlice'
import { usePostUserMutation } from '../../redux/users/userAPI'
import { useLazyGetOrganizationAndChildsQuery } from '../../redux/organizations/organizationAPI'
import { useLazyGetRolesQuery } from '../../redux/roles/roleAPI'
import { updateOrganizationAndChilds } from '../../redux/organizations/organizationSlice'
// AUTHORIZATION
import GETJwtToken from '../../redux/authentication'
// STYLES

library.add(faUser, faBuildingCircleCheck)
const { Option } = Select

function UsersCreateUserForm() {
  // ************************************************ */
  // LOCAL STORAGE AND VARIABLES ******************** */
  const dispatch = useDispatch()
  const { openModalCreateNewUser } = useSelector((state: any) => state.user)
  const { organizationAndChilds } = useSelector(
    (state: any) => state.organization,
  )
  const [form] = Form.useForm()
  const [idOrganizationSelected, setIdOrganizationSelected] = useState('')
  const masterID = '4f594f28-9896-4ffb-9e40-0693c06bde69'
  const [listOrganizations, setListOrganizations] = useState<any[]>([])
  const [listRoles, setListRoles] = useState<any[]>([])
  const [t] = useTranslation('global')
  const [
    postUser,
    {
      isSuccess: isSuccessPostUser,
      isLoading: isLoadingPostUser,
      isError: isErrorPostUser,
      reset: resetPostUser,
    },
  ] = usePostUserMutation()
  const [
    triggerGetOrganizationAndChilds,
    { isError: isErrorGetOrganizationAndChilds },
  ] = useLazyGetOrganizationAndChildsQuery()
  const [triggerGetRoles, { data: dataGetRoles }] = useLazyGetRolesQuery()

  // ************************************************ */
  // FUNCTIONS ************************************** */
  const cleanOrganizationsList = () => {
    dispatch(
      updateOrganizationAndChilds({
        childs: [],
      }),
    )
  }

  const onClose = () => {
    cleanOrganizationsList()
    dispatch(updateOpenModalCreateNewUser(false))
  }

  const onFinish = async (values: any) => {
    const token = await GETJwtToken()
    const BODY = {
      token,
      body: {
        contact_info: [
          {
            address: values.address,
            phone: values.phone.toString(),
            phone_code: values.dialCode.toString(),
            priority: 0,
          },
        ],
        email: values.email,
        first_name: values.firstName,
        middle_name: values.middleName,
        last_name: values.lastName,
        org_id:
          idOrganizationSelected !== '' ? idOrganizationSelected : masterID,
        role_id: values.role,
      },
    }
    postUser(BODY)
  }

  const handleCloseModal = () => {
    cleanOrganizationsList()
    dispatch(updateOpenModalCreateNewUser(false))
  }

  const getOrganizationAndChilds = async (orgId: string) => {
    const token = await GETJwtToken()
    const data = await triggerGetOrganizationAndChilds({
      orgId,
      token,
    }).unwrap()
    if (
      data &&
      data.data &&
      data.data.children &&
      data.data.children.length > 0
    ) {
      const BODY = {
        childs: [
          ...organizationAndChilds.childs,
          {
            level: organizationAndChilds.childs.length,
            data: data.data.children,
          },
        ],
      }
      dispatch(updateOrganizationAndChilds(BODY))
    }
  }

  const handleChangeOrganization = (value: string, level: number) => {
    const totalChilds = organizationAndChilds.childs.length
    // if we select a new value
    if (value) {
      setIdOrganizationSelected(value)
      getOrganizationAndChilds(value)
    }
    // if we select the last value of the list
    else if (totalChilds === level) {
      setIdOrganizationSelected(
        organizationAndChilds.childs[level - 1].orgSelected,
      )
      form.setFieldsValue({ [`assignToOrganization${level}`]: undefined })
    }
    // if we select a middle value
    else {
      const newArray = []
      setIdOrganizationSelected(
        organizationAndChilds.childs[level - 1].orgSelected,
      )
      for (let index = 0; index < level; index += 1) {
        newArray.push(organizationAndChilds.childs[index])
      }
      for (let index = level + 1; index <= totalChilds; index += 1) {
        form.setFieldsValue({ [`assignToOrganization${index}`]: undefined })
      }
      const BODY = {
        childs: newArray,
      }
      dispatch(updateOrganizationAndChilds(BODY))
    }
  }

  const launchGetRoles = async () => {
    const token = await GETJwtToken()
    triggerGetRoles({ token })
  }

  // ************************************************* */
  // USE EFFECT ************************************** */
  useEffect(() => {
    if (openModalCreateNewUser) {
      resetPostUser()
      form.resetFields()
      cleanOrganizationsList()
      getOrganizationAndChilds(masterID)
      launchGetRoles()
    }
  }, [openModalCreateNewUser])

  useEffect(() => {
    if (isSuccessPostUser) {
      setTimeout(() => {
        dispatch(
          updateUserAlert({
            title: 'Success',
            description: 'User created successfully',
            status: 'success',
          }),
        )
      }, 150)
      cleanOrganizationsList()
      dispatch(updateOpenModalCreateNewUser(false))
    }
    if (isErrorPostUser) {
      setTimeout(() => {
        dispatch(
          updateUserAlert({
            title: 'Error',
            description:
              'Something went wrong! Please reload the page and try again.',
            status: 'error',
          }),
        )
      }, 150)
      cleanOrganizationsList()
      dispatch(updateOpenModalCreateNewUser(false))
    }
  }, [isSuccessPostUser, isErrorPostUser])

  useEffect(() => {
    if (isErrorGetOrganizationAndChilds) {
      setTimeout(() => {
        dispatch(
          updateUserAlert({
            title: 'Error',
            description:
              'Something went wrong! Please reload the page and try again.',
            status: 'error',
          }),
        )
      }, 150)
      cleanOrganizationsList()
      dispatch(updateOpenModalCreateNewUser(false))
    }
  }, [isErrorGetOrganizationAndChilds])

  useEffect(() => {
    if (organizationAndChilds && organizationAndChilds.childs) {
      setListOrganizations(organizationAndChilds.childs)
    }
  }, [organizationAndChilds])

  useEffect(() => {
    if (dataGetRoles && dataGetRoles.data) {
      setListRoles(dataGetRoles.data)
    }
  }, [dataGetRoles])

  // ************************************************ */
  // COMPONENT ************************************** */
  return (
    <Drawer
      width="35%"
      placement="right"
      onClose={onClose}
      closable={false}
      visible={openModalCreateNewUser}
    >
      <Spin spinning={isLoadingPostUser} tip={t('general.loading')}>
        <div>
          <h5>
            <FontAwesomeIcon
              style={{ marginRight: '10px' }}
              icon={faBuildingCircleCheck}
              className="generalStyles__info"
            />
            <span className="generalStyles__text">
              {t('userManagement.createNewUser')}
            </span>
          </h5>
          <Divider />
        </div>
        <Form form={form} layout="vertical" onFinish={onFinish}>
          <div className="container">
            <div className="row">
              <div className="col-12">
                {/** ---------------------------------------------------- */}
                {/** ROLE */}
                <Form.Item
                  name="role"
                  label={
                    <>
                      <span className="generalStyles__text">
                        {t('userManagement.role')}
                      </span>
                    </>
                  }
                  rules={[
                    {
                      required: true,
                      message: t('userManagement.formRequiredMessage.role'),
                    },
                  ]}
                >
                  <Select
                    disabled={listRoles.length === 0}
                    showSearch
                    optionFilterProp="children"
                    filterOption={(input: any, option: any) =>
                      option.children
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                  >
                    {listRoles.map((st) => (
                      <Option key={st.id} value={st.id}>
                        {st.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </div>
              <div className="col-12">
                {/** ---------------------------------------------------- */}
                {/** ASSIGN TO ORGANIZATION */}
                {listOrganizations.map((organization: any) => (
                  <Form.Item
                    key={organization.level}
                    name={`assignToOrganization${organization.level}`}
                    label={
                      <>
                        <span className="generalStyles__text">
                          {organization.level === 0 ? (
                            <>{t('userManagement.assignToOrganization')}</>
                          ) : (
                            <>{`${t('userManagement.subOrganization')}`}</>
                          )}
                        </span>
                      </>
                    }
                    rules={[
                      {
                        required: organization.level === 0,
                        message:
                          organization.level === 0
                            ? t(
                                'userManagement.formRequiredMessage.organization',
                              )
                            : t(
                                'userManagement.formRequiredMessage.subOrganization',
                              ),
                      },
                    ]}
                  >
                    <Select
                      showSearch
                      optionFilterProp="children"
                      onChange={(value) => {
                        handleChangeOrganization(value, organization.level)
                      }}
                      allowClear
                      filterOption={(input: any, option: any) =>
                        option.children
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                    >
                      {organization.data.map((item: any) => (
                        <Option key={item.id} value={item.id}>
                          {item.name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                ))}
              </div>
              <div className="col-12">
                {/** ---------------------------------------------------- */}
                {/** FIRTS NAME */}
                <Form.Item
                  name="firstName"
                  label={
                    <>
                      <span className="generalStyles__text">
                        {t('userManagement.firstName')}
                      </span>
                    </>
                  }
                  rules={[
                    {
                      required: true,
                      message: t(
                        'userManagement.formRequiredMessage.firstName',
                      ),
                    },
                  ]}
                >
                  <Input className="generalStyles__input" />
                </Form.Item>
              </div>
              <div className="col-12">
                {/** ---------------------------------------------------- */}
                {/** MIDDLE NAME */}
                <Form.Item
                  name="middleName"
                  label={
                    <>
                      <span className="generalStyles__text">
                        {t('userManagement.middleName')}
                      </span>
                    </>
                  }
                  rules={[
                    {
                      message: t(
                        'userManagement.formRequiredMessage.middleName',
                      ),
                    },
                  ]}
                >
                  <Input className="generalStyles__input" />
                </Form.Item>
              </div>
              <div className="col-12">
                {/** ---------------------------------------------------- */}
                {/** LAST NAME */}
                <Form.Item
                  name="lastName"
                  label={
                    <>
                      <span className="generalStyles__text">
                        {t('userManagement.lastName')}
                      </span>
                    </>
                  }
                  rules={[
                    {
                      required: true,
                      message: t('userManagement.formRequiredMessage.lastName'),
                    },
                  ]}
                >
                  <Input className="generalStyles__input" />
                </Form.Item>
              </div>
              <div className="col-12">
                {/** ---------------------------------------------------- */}
                {/** EMAIL */}
                <Form.Item
                  name="email"
                  label={
                    <>
                      <span className="generalStyles__text">
                        {t('userManagement.email')}
                      </span>
                    </>
                  }
                  rules={[
                    {
                      required: true,
                      message: t('userManagement.formRequiredMessage.email'),
                    },
                  ]}
                >
                  <Input className="generalStyles__input" />
                </Form.Item>
              </div>
              <div className="col-12">
                {/** ---------------------------------------------------- */}
                {/** ADDRESS */}
                <Form.Item
                  name="address"
                  label={
                    <>
                      <span className="generalStyles__text">
                        {t('userManagement.address')}
                      </span>
                    </>
                  }
                  rules={[
                    {
                      required: true,
                      message: t('userManagement.formRequiredMessage.address'),
                    },
                  ]}
                >
                  <Input className="generalStyles__input" />
                </Form.Item>
              </div>
              <div className="col-12">
                {/** ---------------------------------------------------- */}
                {/** PHONE */}
                <Form.Item
                  name="phone"
                  label={
                    <>
                      <span className="generalStyles__text">
                        {t('organizationManagement.phoneNumber')}
                      </span>
                    </>
                  }
                  rules={[
                    {
                      required: true,
                      message: t(
                        'organizationManagement.formRequiredMessage.phone',
                      ),
                      type: 'number',
                    },
                  ]}
                >
                  <InputNumber
                    addonBefore={
                      <Form.Item
                        name="dialCode"
                        rules={[
                          {
                            required: true,
                            message: t(
                              'userManagement.formRequiredMessage.dialCode',
                            ),
                          },
                        ]}
                        style={{ backgroundColor: 'red' }}
                        noStyle
                      >
                        <InputNumber
                          prefix="+"
                          style={{
                            width: '100px',
                            marginLeft: '-12px',
                            marginRight: '-12px',
                            borderTop: 'none',
                            borderBottom: 'none',
                          }}
                        />
                      </Form.Item>
                    }
                    style={{ width: '100%' }}
                  />
                </Form.Item>
              </div>
            </div>
          </div>
          {/** ---------------------------------------------------- */}
          <div className="mt-4">
            <div>
              <Button
                htmlType="submit"
                disabled={isLoadingPostUser}
                style={{ marginRight: '10px' }}
                type="primary"
              >
                {t('userManagement.submit')}
              </Button>
              <Button type="default" onClick={handleCloseModal}>
                {t('userManagement.close')}
              </Button>
            </div>
          </div>
        </Form>
      </Spin>
    </Drawer>
  )
}

export default UsersCreateUserForm
