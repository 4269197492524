import { createApi, fetchBaseQuery, retry } from '@reduxjs/toolkit/query/react';

const maxRetries = 2;

const baseQuery = retry(
  fetchBaseQuery({
    baseUrl: process.env.REACT_APP_LIFT_NET_CLOUD_API,
  }),
  { maxRetries }
);

export const organizationAPI = createApi({
  reducerPath: 'organizationAPI',
  baseQuery,
  keepUnusedDataFor: 0,
  tagTypes: [
    'tagGetOrganizations',
    'tagPostOrganization',
    'tagPutOrganization',
  ],
  refetchOnMountOrArgChange: true,
  refetchOnReconnect: true,
  endpoints: (builder) => ({
    //* *********************************************************** */
    //* GET ALL ORGANIZATIONS */
    getOrganizations: builder.query({
      query: (body: any) => ({
        url: `/orgs/?page=${body.page}&limit=${body.limit}`,
        headers: { authorization: body.token },
      }),
      providesTags: ['tagGetOrganizations'],
    }),
    //* *********************************************************** */
    //* GET SPECIFIC ORGANIZATION AND ITS CHILDRENS */
    getOrganizationAndChilds: builder.query({
      query: (body: any) => ({
        url: `/orgs/${body.orgId}`,
        headers: { authorization: body.token },
      }),
    }),
    //* *********************************************************** */
    //* CREATE AN ORGANIZATION */
    postOrganization: builder.mutation({
      query: (body: any) => ({
        url: '/orgs/',
        method: 'post',
        body: body.body,
        headers: { authorization: body.token },
      }),
      invalidatesTags: ['tagGetOrganizations'],
    }),
    //* *********************************************************** */
    //* EDIT AN ORGANIZATION */
    putOrganization: builder.mutation({
      query: (body: any) => ({
        url: `/orgs/${body.id}`,
        method: 'put',
        body: body.body,
        headers: { authorization: body.token },
      }),
      invalidatesTags: ['tagGetOrganizations'],
    }),
    //* *********************************************************** */
    //* DELETE AN ORGANIZATION */
    deleteOrganization: builder.mutation({
      query: (body: any) => ({
        url: `/orgs/${body.id}`,
        method: 'delete',
        headers: { authorization: body.token },
      }),
      invalidatesTags: ['tagGetOrganizations'],
    }),
    //* *********************************************************** */
  }),
});

export const {
  useLazyGetOrganizationsQuery,
  useLazyGetOrganizationAndChildsQuery,
  usePostOrganizationMutation,
  usePutOrganizationMutation,
  useDeleteOrganizationMutation,
} = organizationAPI;
