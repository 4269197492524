import React from 'react'
import 'react-pro-sidebar/dist/css/styles.css'
// ROUTER
import AppRouter from './routers/AppRouter'
// STYLES
import './styles/generalStyles.scss'
import './App.less'

function App() {
  return <AppRouter />
}

export default App
