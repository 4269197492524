import React from 'react'
import { Navigate } from 'react-router-dom'

function PrivateRoute({ Component }: any) {
  // ************************************************ */
  // LOCAL STORAGE AND VARIABLES ******************** */
  const loginState = localStorage.getItem('useLoggedAmplify')

  // ************************************************ */
  // COMPONENT ************************************** */
  return loginState === 'true' ? <Component /> : <Navigate to="/login" />
}

export default PrivateRoute
