import { Auth } from 'aws-amplify'

const GETJwtToken = () =>
  new Promise((resolve) => {
    Auth.currentSession().then((data) => {
      const jwt = data.getIdToken()      
      resolve(`Bearer ${jwt.getJwtToken()}`)
    })
  })

  export default GETJwtToken