import { createApi, fetchBaseQuery, retry } from '@reduxjs/toolkit/query/react';

const maxRetries = 2;

const baseQuery = retry(
  fetchBaseQuery({
    baseUrl: process.env.REACT_APP_LIFT_NET_CLOUD_API,
  }),
  { maxRetries }
);

export const roleAPI = createApi({
  reducerPath: 'roleAPI',
  baseQuery,
  keepUnusedDataFor: 0,
  tagTypes: ['tagGetRoles'],
  refetchOnMountOrArgChange: true,
  refetchOnReconnect: true,
  endpoints: (builder) => ({
    //* *********************************************************** */
    //* GET ALL ROLES */
    getRoles: builder.query({
      query: (body: any) => ({
        url: `/roles/`,
        headers: { authorization: body.token },
      }),
      providesTags: ['tagGetRoles'],
    }),
    //* *********************************************************** */
    //* CREATE ROLE */
    postRole: builder.mutation({
      query: (body: any) => ({
        url: '/roles/',
        method: 'post',
        body: body.body,
        headers: { authorization: body.token },
      }),
      invalidatesTags: ['tagGetRoles'],
    }),
    //* *********************************************************** */
    //* EDIT ROLE */
    putRole: builder.mutation({
      query: (body: any) => ({
        url: `/roles/${body.id}`,
        method: 'put',
        body: body.body,
        headers: { authorization: body.token },
      }),
      invalidatesTags: ['tagGetRoles'],
    }),
    //* *********************************************************** */
    //* DELETE ROLE */
    deleteRole: builder.mutation({
      query: (body: any) => ({
        url: `/roles/${body.id}`,
        method: 'delete',
        headers: { authorization: body.token },
      }),
      invalidatesTags: ['tagGetRoles'],
    }),
    //* *********************************************************** */
  }),
});

export const {
  useLazyGetRolesQuery,
  usePostRoleMutation,
  usePutRoleMutation,
  useDeleteRoleMutation,
} = roleAPI;
