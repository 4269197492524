import React, { useEffect } from 'react'
// ANT DESIGN COMPONENTS
import { notification } from 'antd'
// REDUX
import { useSelector, useDispatch } from 'react-redux'
import { updateOrganizationAlert } from '../../redux/organizations/organizationSlice'
import { updateUserAlert } from '../../redux/users/userSlice'

// STYLES

function GlobalAlert() {
  // ************************************************ */
  // LOCAL STORAGE AND VARIABLES ******************** */
  const dispatch = useDispatch()
  const { organizationAlert } = useSelector((state: any) => state.organization)
  const { userAlert } = useSelector((state: any) => state.user)
  type NotificationType = 'success' | 'info' | 'warning' | 'error'

  // ************************************************ */
  // FUNCTIONS ************************************** */
  // eslint-disable-next-line no-unused-vars
  const launchNotification = (
    title: string,
    text: string,
    status: NotificationType,
  ) => {
    let customClass = ''
    if (status === 'success') {
      customClass = 'generalStyles__notificationSuccess'
    } else if (status === 'info') {
      customClass = 'generalStyles__notificationInfo'
    } else if (status === 'warning') {
      customClass = 'generalStyles__notificationWarning'
    } else if (status === 'error') {
      customClass = 'generalStyles__notificationError'
    }
    notification[status]({
      message: title,
      description: text,
      placement: 'top',
      className: customClass,
    })
    // clean alert
    dispatch(
      updateOrganizationAlert({
        title: '',
        description: '',
        status: '',
      }),
    )
    dispatch(
      updateUserAlert({
        title: '',
        description: '',
        status: '',
      }),
    )
  }
  // ************************************************* */
  // USE EFFECT ************************************** */

  useEffect(() => {
    if (
      organizationAlert &&
      organizationAlert.title &&
      organizationAlert.description &&
      organizationAlert.status
    ) {
      launchNotification(
        organizationAlert.title,
        organizationAlert.description,
        organizationAlert.status,
      )
    }
  }, [organizationAlert])

  useEffect(() => {
    if (
      userAlert &&
      userAlert.title &&
      userAlert.description &&
      userAlert.status
    ) {
      launchNotification(
        userAlert.title,
        userAlert.description,
        userAlert.status,
      )
    }
  }, [userAlert])

  // ************************************************ */
  // COMPONENT ************************************** */
  return (
    <div>
      <div id="global-alert" />
    </div>
  )
}

export default GlobalAlert
