import React from 'react'
// COMPONENTS
import UsersTable from './UsersTable'
import UsersHeader from './UsersHeader'
import UsersCreateUserForm from './UsersCreateUserForm'
import UsersDrawer from './UsersDrawer'
import UsersEditUserForm from './UsersEditUserForm'
import UsersDeleteUserModal from './UsersDeleteUserModal'
import UsersResetPasswordModal from './UsersResetPasswordModal'
import UsersBlockUnBlockModal from './UsersBlockUnBlockModal'

// eslint-disable-next-line arrow-body-style
const UserPage = () => {
  // ************************************************ */
  // LOCAL STORAGE AND VARIABLES ******************** */

  // ************************************************* */
  // USE EFFECT ************************************** */

  // ************************************************ */
  // COMPONENT ************************************** */
  return (
    <div>
      <div className="generalStyles__pageBox">
        <UsersHeader />
        <UsersTable />
        <UsersCreateUserForm />
        <UsersDrawer />
        <UsersEditUserForm />
        <UsersDeleteUserModal />
        <UsersResetPasswordModal />
        <UsersBlockUnBlockModal />
      </div>
    </div>
  )
}

export default UserPage
